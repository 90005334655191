import { useQuery } from '@tanstack/react-query';

import { fetchStreamingPlainText } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/investigations/summary/prompt';

const getAcceptanceState = (): Promise<string> => fetchStreamingPlainText(
  'GET',
  qualifyUrl(urlPrefix),
);

const useDefaultPrompt = ():{
  data: string,
  isLoading: boolean,
} => {
  const {
    data,
    isLoading,
  } = useQuery<string>(
    ['investigation-ai-default-prompt'],
    getAcceptanceState,
    {
      onError: (errorThrown) => {
        UserNotification.error(`Fetching default prompt failed with status: ${errorThrown}.`,
          'Could not fetch default prompt');
      },
    });

  return {
    data: data ?? '',
    isLoading,
  };
};

export default useDefaultPrompt;
