import * as React from 'react';
import PropTypes from 'prop-types';

import PrintWidget from 'common/components/widgetRenderers/PrintWidget';
import type { ExportWidgetData, ExportWidgetValues } from 'search/ExportWidgetAction/types';

type Props = {
  widget: ExportWidgetData,
  value: ExportWidgetValues,
  height: number,
  width: number,
}

const ExportingWidget = ({
  widget,
  value,
  height,
  width,
}: Props) => (
  <PrintWidget<ExportWidgetData, ExportWidgetValues> widget={widget}
                                                     widgetId={widget?.search_type_id}
                                                     value={value}
                                                     showCaption
                                                     showHeading
                                                     showHandle={false}
                                                     height={height}
                                                     width={width}
                                                     interactive={false}
                                                     limitHeight={false}
                                                     hideDescription
                                                     header=""
                                                     hideQuery />
);

ExportingWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

ExportingWidget.defaultProps = {
  height: undefined,
  width: undefined,
};

export default ExportingWidget;
