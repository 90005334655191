import React from 'react';

import DataWarehousePageNavigation from 'data-warehouse/components/DataWarehousePageNavigation';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { Row, Col } from 'components/bootstrap';
import useDataWarehouseBackends from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackends';
import useDataWarehouseConfig from 'data-warehouse/configurations/hooks/useDataWarehouseConfig';

import DataWarehouseConfigForm from './configurations/DataWarehouseConfigForm';

const DataWarehouseConfigPage = () => {
  const { data: backendsList, isInitialLoading: isLoadingBackends } = useDataWarehouseBackends({ enabled: true });
  const { data: config, isInitialLoading: isLoadingCurrentConfig } = useDataWarehouseConfig();

  if (isLoadingBackends || isLoadingCurrentConfig) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Data Warehouse Configuration">
      <DataWarehousePageNavigation />
      <PageHeader title="Data Warehouse Configuration">
        <span>
          Manage Data Warehouse configuration
        </span>
      </PageHeader>
      <Row className="content">
        <Col md={12}>
          <DataWarehouseConfigForm backends={backendsList} config={config} />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default DataWarehouseConfigPage;
