import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, Formik, Form } from 'formik';
import mapValues from 'lodash/mapValues';

import { DATA_WAREHOUSE_CONFIG_ACTION } from 'data-warehouse/Types';
import { Modal, Input, HelpBlock } from 'components/bootstrap';
import { ModalSubmit, Select } from 'components/common';
import { FILESYSTEM_TYPE, S3_TYPE } from 'archive/ArchiveStore';
import {
  useCreateDataWarehouseBackend, useUpdateDataWarehouseBackend,
} from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackendMutation';
import useDataWarehouseBackend from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackend';
import ArchiveBackendNameDescriptionFormGroup from 'archive/components/ArchiveBackendNameDescriptionFormGroup';
import ArchiveBackendS3FieldGroup from 'archive/components/ArchiveBackendS3FieldGroup';
import ArchiveBackendOutputPathFieldGroup from 'archive/components/ArchiveBackendOutputPathFieldGroup';
import AWSRegionsFormGroup from 'archive/components/aws/AWSRegionsFormGroup';
import type { Backend, BackendCreate } from 'archive/types';
import ArchiveActions from 'archive/ArchiveActions';
import type FetchError from 'logic/errors/FetchError';

type Props = {
  backendId?: string,
  onClose: () => void,
}

const formatBackendValidationErrors = (backendErrors: { [fieldName: string]: string[] }) => {
  const backendErrorStrings = mapValues(backendErrors, (errorArray) => `${errorArray.join(' ')}`);
  const { title, description, ...rest } = backendErrorStrings;

  return {
    title: title || undefined,
    description: description || undefined,
    settings: {
      ...rest,
    },
  };
};

const DataWarehouseBackendFormModal = ({ backendId, onClose }: Props) => {
  const action: DATA_WAREHOUSE_CONFIG_ACTION = backendId ? DATA_WAREHOUSE_CONFIG_ACTION.Update : DATA_WAREHOUSE_CONFIG_ACTION.Create;
  const modalTitle = action === DATA_WAREHOUSE_CONFIG_ACTION.Update ? 'Edit Data Warehouse Backend' : 'Create Data Warehouse Backend';
  const submitButtonText = action === DATA_WAREHOUSE_CONFIG_ACTION.Update ? 'Update' : 'Create';
  const submitLoadingText = action === DATA_WAREHOUSE_CONFIG_ACTION.Update ? 'Updating...' : 'Creating...';
  const BACKEND_TYPE_OPTIONS = [{ value: FILESYSTEM_TYPE, label: 'File system' }, { value: S3_TYPE, label: 'S3' }];

  const { data: backend, isSuccess: isBackendLoaded } = useDataWarehouseBackend(backendId, !!backendId);
  const {
    mutateAsync: createBackend,
    isLoading: isCreatingBackend,
  } = useCreateDataWarehouseBackend(onClose);
  const {
    mutateAsync: updateBackend,
    isLoading: isUpdatingBackend,
  } = useUpdateDataWarehouseBackend(onClose);
  const prepareInitialValues = (existingBackend: Backend | undefined) => ({
    ...(existingBackend?.id && { id: existingBackend }),
    title: existingBackend?.title ?? '',
    description: existingBackend?.description ?? '',
    settings: existingBackend?.settings ?? { type: undefined },
  });
  const initialValues = useMemo(
    () => prepareInitialValues(backend),
    [backend],
  );

  if (backendId && !isBackendLoaded) { return null; }

  const setFormErrors = (error: FetchError, setErrors: (fields: { [field: string]: string }) => void) => {
    if (typeof error?.additional?.body?.errors === 'object') {
      setErrors(formatBackendValidationErrors(error.additional.body.errors));
    }
  };

  const handleSubmit = (values: BackendCreate | Backend, { setErrors }) => {
    if (action === DATA_WAREHOUSE_CONFIG_ACTION.Update) {
      updateBackend({ id: backendId, ...values } as Backend).catch((error: FetchError) => {
        setFormErrors(error, setErrors);
      });
    } else {
      createBackend(values).catch((error) => {
        setFormErrors(error, setErrors);
      });
    }
  };

  const checkOutputPath = (path: string) => ArchiveActions.checkOutputTemplate(path);

  return (
    <Modal title={modalTitle}
           onHide={onClose}
           bsSize="large"
           show>
      <Formik initialValues={initialValues}
              onSubmit={handleSubmit}>
        {({
          values,
          setFieldValue,
          isSubmitting,
        }) => {
          const resetS3Fields = () => {
            setFieldValue('settings.bucket', undefined);
            setFieldValue('settings.endpoint_uri', undefined);
            setFieldValue('settings.aws_access_key_id', undefined);
            setFieldValue('settings.aws_secret_access_key', undefined);
            setFieldValue('settings.aws_assume_role_arn', undefined);
            setFieldValue('settings.spool_directory', undefined);
          };

          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field name="settings.type">
                  {() => (
                    <Input help="Select the Backend type you want to configure."
                           id="default-backend-type-select"
                           label="Backend Type">
                      <Select id="backendType"
                              name="BackendType"
                              placeholder="Select Backend Type"
                              options={BACKEND_TYPE_OPTIONS}
                              matchProp="label"
                              disabled={!!backendId}
                              onChange={(option) => {
                                resetS3Fields();
                                setFieldValue('settings.type', option);
                              }}
                              value={values.settings.type} />
                      <HelpBlock />
                    </Input>
                  )}
                </Field>
                {values.settings.type && <ArchiveBackendNameDescriptionFormGroup />}
                {values.settings.type === S3_TYPE && (
                <>
                  <ArchiveBackendS3FieldGroup enableSpool={false} />
                  <AWSRegionsFormGroup />
                </>
                )}
                {values.settings.type && (
                <ArchiveBackendOutputPathFieldGroup checkOutputPath={checkOutputPath} noPreview />
                )}
              </Modal.Body>
              <Modal.Footer>
                {values.settings.type && (
                <ModalSubmit submitButtonText={submitButtonText}
                             submitLoadingText={submitLoadingText}
                             onCancel={onClose}
                             disabledSubmit={isCreatingBackend || isUpdatingBackend}
                             isSubmitting={isSubmitting} />
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

DataWarehouseBackendFormModal.propTypes = {
  backendId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

DataWarehouseBackendFormModal.defaultProps = {
  backendId: undefined,
};

export default DataWarehouseBackendFormModal;
