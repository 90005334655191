import MessageDetailsAdditionalProvider from 'search/contexts/MessageDetailsAdditionalProvider';
import MessageSummaryTemplateProvider from 'search/contexts/MessageSummaryTemplateProvider';
import eventsAttributes from 'search/events/eventsAttributes';
import eventFilterComponents from 'search/events/filterComponents';
import EventDetails from 'search/events/EventDetails';
import useLicenseCheck from 'license/useLicenseCheck';
import validLicensePresent from 'license/ValidLicensePresent';
import ExportAggregationWidgetAction from 'search/ExportWidgetAction/ExportAggregationWidgetAction';
import ExportEventsOverviewWidgetAction from 'search/ExportWidgetAction/ExportEventsOverviewWidgetAction';
import ExportInvestigationsOverviewWidgetAction
  from 'search/ExportWidgetAction/ExportInvestigationsOverviewWidgetAction';
import ExportWidgetPage from 'search/ExportWidgetAction/pdf-export-widget-renderer/ExportWidgetPage';

import MessageSummary from './MessageSummary';

const bindings = {
  routes: [
    { path: 'widgetexport/:exportCallId/render', component: ExportWidgetPage, parentComponent: null },
  ],
  'views.components.widgets.messageTable.previewOptions': [
    {
      title: 'Show summary',
      isChecked: (config) => config.showSummary,
      isDisabled: () => false,
      help: 'Display a summary of the most relevant information of a message, based on the message event type. When a summary is available it will replace the message.',
      onChange: (config, onConfigChange) => {
        if (config.showSummary) {
          const newConfig = config.toBuilder().showSummary(false).build();

          return onConfigChange(newConfig);
        }

        const newConfig = config.toBuilder()
          .showMessageRow(true)
          .showSummary(true)
          .build();

        return onConfigChange(newConfig);
      },
      sort: 2,
    },
  ],
  'views.components.widgets.messageTable.messageRowOverride': [MessageSummary],
  'views.components.widgets.messageDetails.contextProviders': [MessageDetailsAdditionalProvider],
  'views.components.widgets.messageTable.contextProviders': [MessageSummaryTemplateProvider],
  'views.components.widgets.events.attributes': eventsAttributes,
  'views.components.widgets.events.filterComponents': eventFilterComponents,
  'views.components.widgets.events.detailsComponent': [
    {
      component: EventDetails,
      useCondition: () => {
        const { security } = useLicenseCheck();

        return security?.isValid;
      },
    },
  ],
  'views.widgets.exportAction': [
    { useCondition: validLicensePresent, action: ExportAggregationWidgetAction },
    { useCondition: validLicensePresent, action: ExportEventsOverviewWidgetAction },
  ],
  'views.widgets.actions': [
    ExportInvestigationsOverviewWidgetAction,
  ],
};

export default bindings;
