import * as React from 'react';
import { useState } from 'react';

import Modal from 'components/bootstrap/Modal';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import DisclaimerModalContent from 'security-app/components/Investigations/AIReport/DisclaimerModalContent';
import AIReportModalContent from 'security-app/components/Investigations/AIReport/AIReportModalContent';
import useFeature from 'hooks/useFeature';

type Props = {
  isConsent: boolean,
  onCancel: () => void;
  investigation: InvestigationAPIType,
};

type Step = 'consent' | 'report' | 'survey';

const AIReportModal = ({ isConsent, onCancel, investigation }: Props) => {
  const isAIReportFeatureEnabled = useFeature('investigation_report_by_ai');
  const [step, setStep] = useState<Step>(isConsent ? 'report' : 'consent');

  const onConsent = () => {
    setStep('report');
  };

  return (
    <Modal show={isAIReportFeatureEnabled} onHide={onCancel} role="alertdialog" bsSize="large">
      {step === 'consent' && <DisclaimerModalContent onClose={onCancel} onConsent={onConsent} />}
      {step === 'report' && <AIReportModalContent onClose={onCancel} investigation={investigation} />}
    </Modal>
  );
};

export default AIReportModal;
