import * as React from 'react';
import styled, { css } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import type { OrderedMap } from 'immutable';
import { useState } from 'react';

import Store from 'logic/local-storage/Store';
import { MarkdownEditor } from 'components/common/MarkdownEditor';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';
import { Icon, MultiSelect, TimeUnitInput, SearchFiltersFormControls, TimezoneSelect } from 'components/common';
import { ControlLabel, Button, Input } from 'components/bootstrap';
import { useGetStreams, useGetNotifications } from 'security-app/hooks/useSigmaAPI';
import type { GitRepoContent } from 'security-app/hooks/api/sigmaAPI.types';
import { SIGMA_GUARDRAILS } from 'security-app/components/SigmaRules/ImportRulesModal/SigmaModal';
import useUserDateTime from 'hooks/useUserDateTime';
import { describeExpression } from 'util/CronUtils';

import {
  File,
  IconButton,
  InfoButton,
  RuleDescription,
} from '../ImportRepoRulesModal.styles';
import type { FormRuleAction } from '../ImportRepoRulesModal';

export const PLUGGABLE_CONTROLS_HIDDEN_KEY = 'pluggableSearchBarControlsAreHidden';

const RemoveButton = styled(IconButton)`
  opacity: 0.3;

  &:hover {
    opacity: 0.9;
  }
`;

const CronCheckbox = styled(Input)`
  margin-top: -10px;
  margin-bottom: -10px;
`;

const SelectedRule = styled(File)`
  justify-content: space-between;
`;

const Form = styled.form(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: max-height 0.4s;

  &.open {
    padding: 8px;
    border: 1px solid ${theme.colors.global.navigationBackground};
    animation: 0.7s linear out;
    animation-fill-mode: forwards;
  }

  &.collapsed {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    border: none;
  }

  @keyframes out {
    0% {
      overflow: hidden;
    }

    99% {
      overflow: hidden;
    }

    100% {
      overflow: visible;
    }
  }
`);

const Row = styled.div<{ $gap?: string, $fullWidth?: boolean }>(({ $gap, $fullWidth }) => css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${$gap || '1rem'};

  & > * {
    width: ${$fullWidth ? '100%' : 'auto'};
  }
`);

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

const ErrorMessage = styled.small`
  color: ${({ theme }) => theme.colors.variant.danger};
`;

type FormField<T> = {
  value: T,
  touched: boolean,
  valid: boolean,
};

export type FormRule = GitRepoContent & {
  search_within: FormField<number>,
  search_within_unit: FormField<'HOURS'|'MINUTES'>,
  execute_every: FormField<number>,
  execute_every_unit: FormField<'HOURS'|'MINUTES'>,
  streams: FormField<string[]>,
  notifications: FormField<string[]>,
  filters: FormField<SearchFilter[]>,
  remediation_steps: FormField<string>,
  use_cron_scheduling: FormField<boolean>,
  cron_expression: FormField<string>,
  cron_timezone: FormField<string>,
};

type Props = {
  expand?: boolean,
  inFormRule: FormRule,
  formRulesDispatch: (action: FormRuleAction) => void,
  showCopySettingsButton?: boolean,
};

const SelectedRuleForm = ({ expand, inFormRule, formRulesDispatch, showCopySettingsButton }: Props) => {
  const [formRule, setFormRule] = React.useState<FormRule>(inFormRule);
  const [streamsUpdate, setStreamsUpdate] = React.useState<any>();
  const [notificationsUpdate, setNotificationsUpdate] = React.useState<any>();
  const [timesUpdate, setTimesUpdate] = React.useState<any>();
  const [searchFiltersUpdate, setSearchFiltersUpdate] = React.useState<any>();
  const [remediationStepsUpdate, setRemediationStepsUpdate] = React.useState<any>();
  const [cronUpdate, setCronUpdate] = React.useState<any>();
  const [searchFiltersHidden, setSearchFiltersHidden] = React.useState(Store.get(PLUGGABLE_CONTROLS_HIDDEN_KEY));
  const [cronDescription, setCronDescription] = useState<string>(formRule.cron_expression.value ? describeExpression(formRule.cron_expression.value) : '');

  const [open, setOpen] = React.useState<boolean>(expand);
  const [mousePos, setMousePos] = React.useState<{x: number, y: number}>({ x: 0, y: 0 });
  const [showDescription, setShowDescription] = React.useState<boolean>(false);

  const [copied, setCopied] = React.useState<boolean>(false);

  React.useEffect(() => setOpen(expand), [expand]);

  React.useEffect(() => setFormRule(inFormRule), [inFormRule]);

  const { streams } = useGetStreams(expand);
  const { notifications } = useGetNotifications(expand);
  const { userTimezone } = useUserDateTime();

  const streamOptions = React.useMemo(() => (
    streams
      .filter((stream: { is_editable: boolean }) => stream.is_editable)
      .map((stream: { id: string, title: string }) => ({ label: stream.title, value: stream.id }))
  ), [streams]);

  const notificationOptions = React.useMemo(() => (
    notifications
      .map((notification: { id: string, title: string; }) => ({ label: notification.title, value: notification.id }))
  ), [notifications]);

  const unselectRule = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    formRulesDispatch({ type: 'remove', payload: formRule });
  };

  const setStreams = (newValue: string) => {
    const auxFormRule = cloneDeep(formRule);

    auxFormRule.streams.touched = true;
    auxFormRule.streams.value = newValue ? newValue.split(',') : [];

    if (streamsUpdate) clearTimeout(streamsUpdate);

    setStreamsUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const setNotifications = (newValue: string) => {
    const auxFormRule = cloneDeep(formRule);

    auxFormRule.notifications.touched = true;
    auxFormRule.notifications.value = newValue ? newValue.split(',') : [];

    if (notificationsUpdate) clearTimeout(notificationsUpdate);

    setNotificationsUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const validateTimes = (field: string, value: number, unit: string) => {
    let valid = true;

    if (value === null) return valid;

    if (field === 'search_within') {
      let auxValue = ['HOURS', 'DAYS'].includes(unit) ? value * 60 : value;
      auxValue = unit === 'DAYS' ? auxValue * 24 : auxValue;
      valid = auxValue <= SIGMA_GUARDRAILS.maxSearchWithin;
    }

    if (field === 'execute_every') {
      const auxValue = unit === 'HOURS' ? value * 60 : value;
      valid = auxValue >= SIGMA_GUARDRAILS.minExecuteEvery;
    }

    return valid;
  };

  const updateTimes = (field: string) => (newValue: number, unit: string) => {
    const auxFormRule = cloneDeep(formRule);

    auxFormRule[field].valid = newValue > 0;
    auxFormRule[field].touched = true;
    auxFormRule[`${field}_unit`].touched = true;

    auxFormRule[field].value = newValue;
    auxFormRule[`${field}_unit`].value = unit;
    auxFormRule[field].valid = validateTimes(field, newValue, unit);

    if (timesUpdate) clearTimeout(timesUpdate);

    setTimesUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const updateRemediationSteps = (event: Partial<React.BaseSyntheticEvent>) => {
    const auxFormRule = cloneDeep(formRule);

    const { value, name: field } = event.target;

    auxFormRule[field].touched = true;
    auxFormRule[field].value = value;

    if (remediationStepsUpdate) clearTimeout(remediationStepsUpdate);

    setRemediationStepsUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const getSearchFilters = () => formRule.filters.value || [];

  const handleSearchFiltersChange = (field: string) => (filters: OrderedMap<string, SearchFilter>) => {
    const auxFormRule = cloneDeep(formRule);
    auxFormRule[field].touched = true;
    auxFormRule[field].value = filters.toArray();

    if (searchFiltersUpdate) clearTimeout(searchFiltersUpdate);

    setSearchFiltersUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const handleCronFieldChanges = (field: string, value: string | boolean) => {
    const auxFormRule = cloneDeep(formRule);
    auxFormRule[field].touched = true;
    auxFormRule[field].value = value;

    if (field === 'use_cron_scheduling') {
      if (value) {
        auxFormRule.cron_timezone.value = userTimezone;
        auxFormRule.cron_expression.value = '';
      } else {
        auxFormRule.cron_timezone.value = null;
        auxFormRule.cron_expression.value = null;
      }
    }

    if (cronUpdate) clearTimeout(cronUpdate);

    setCronUpdate(
      setTimeout(() => formRulesDispatch({ type: 'update', payload: auxFormRule }), 400),
    );

    setFormRule(auxFormRule);
  };

  const hideFiltersPreview = (value: boolean) => {
    Store.set(PLUGGABLE_CONTROLS_HIDDEN_KEY, value);
    setSearchFiltersHidden(value);
  };

  const handleMouseEnter = (event: React.MouseEvent) => {
    const { x, y } = (event.target as HTMLElement).getBoundingClientRect();
    setMousePos({ x: x + 8, y: y + 8 });
    setShowDescription(true);
  };

  const copySettings = (event: React.SyntheticEvent) => {
    event.preventDefault();
    formRulesDispatch({ type: 'copy-settings', payload: cloneDeep(formRule) });
    setCopied(true);
    // setSearchFiltersHidden(true);
    setTimeout(() => setCopied(false), 3000);
    // setTimeout(() => setSearchFiltersHidden(false), 10);
  };

  return (
    <>
      <SelectedRule onClick={() => setOpen(!open)} data-testid="selected-rule">
        <NameContainer>
          <RemoveButton data-testid="unselect-rule" onClick={unselectRule}>
            <Icon style={{ cursor: 'pointer' }}
                  name="delete"
                  title="Remove rule" />
          </RemoveButton>
          <InfoButton data-testid="info-button"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={() => setShowDescription(false)}>
            <Icon style={{ cursor: 'pointer' }} name="help" size="sm" />
          </InfoButton>
          {formRule.title}
        </NameContainer>
        <IconButton>
          <Icon name={`keyboard_arrow_${open ? 'up' : 'down'}`} size="sm" />
        </IconButton>
      </SelectedRule>
      <Form className={open ? 'open' : 'collapsed'} data-testid="settings-form">
        <Row $fullWidth>
          <div>
            <ControlLabel>Streams</ControlLabel>
            <MultiSelect id={`streams-${formRule.id}`}
                         matchProp="label"
                         onChange={setStreams}
                         value={(formRule.streams.value as string[]).join(',')}
                         options={streamOptions} />
          </div>
        </Row>
        <Row $fullWidth>
          <div>
            <ControlLabel>Notifications</ControlLabel>
            <MultiSelect id={`notifications-${formRule.id}`}
                         matchProp="label"
                         onChange={setNotifications}
                         value={(formRule.notifications.value as string[]).join(',')}
                         options={notificationOptions} />
          </div>
        </Row>
        <Row $fullWidth>
          <div>
            <TimeUnitInput label="Search within"
                           name="search_within"
                           update={updateTimes('search_within')}
                           units={['HOURS', 'MINUTES', 'DAYS']}
                           value={formRule.search_within.value}
                           unit={formRule.search_within_unit.value}
                           clearable
                           required />
            {(!formRule.search_within.valid && formRule.search_within.touched) && <ErrorMessage>Max value is 7 DAYS</ErrorMessage>}
          </div>
        </Row>
        <CronCheckbox id="is-cron-checkbox"
                      type="checkbox"
                      name="use_cron_scheduling"
                      label="Use Cron Scheduling"
                      checked={formRule.use_cron_scheduling.value}
                      onChange={(event: Event) => handleCronFieldChanges('use_cron_scheduling', (event.target as Input).checked)} />
        <Row $fullWidth>
          {formRule.use_cron_scheduling.value
            ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input id="cron-expression"
                       name="cron_expression"
                       label="Cron Expression"
                       type="text"
                       help={(
                         <span style={{ whiteSpace: 'normal' }}>
                           {cronDescription || 'A Quartz cron expression to determine when the event should be run.'}
                         </span>
                       )}
                       onBlur={() => setCronDescription(describeExpression(formRule.cron_expression.value))}
                       value={formRule.cron_expression.value}
                       onChange={(event: Event) => handleCronFieldChanges('cron_expression', (event.target as Input).value)} />
                <div>
                  <ControlLabel>Cron Time Zone</ControlLabel>
                  <TimezoneSelect id="cron_timezone"
                                  value={formRule.cron_timezone.value || userTimezone}
                                  name="cron_timezone"
                                  clearable={false}
                                  onChange={(newValue: string) => handleCronFieldChanges('cron_timezone', newValue)} />
                </div>
              </div>
            )
            : (
              <>
                <TimeUnitInput label="Execute every"
                               name="execute_every"
                               update={updateTimes('execute_every')}
                               units={['HOURS', 'MINUTES']}
                               value={formRule.execute_every.value}
                               unit={formRule.execute_every_unit.value}
                               clearable
                               required />
                {(!formRule.execute_every.valid && formRule.execute_every.touched) && <ErrorMessage>Minimum value is 5 MINUTES</ErrorMessage>}
              </>
            )}
        </Row>
        <Row $fullWidth>
          <div>
            <ControlLabel>Remediation Steps  <small className="text-muted">(Optional)</small></ControlLabel>
            <MarkdownEditor id="remediation_steps"
                            height={150}
                            value={formRule.remediation_steps.value}
                            onChange={(newValue: string) => updateRemediationSteps({ target: { name: 'remediation_steps', value: newValue } })} />
          </div>
        </Row>
        {!searchFiltersHidden && (
          <Row $fullWidth>
            <div>
              <ControlLabel>Search Filters <small className="text-muted">(Optional)</small></ControlLabel>
              <SearchFiltersFormControls filters={getSearchFilters()}
                                         onChange={handleSearchFiltersChange('filters')}
                                         hideFiltersPreview={hideFiltersPreview} />
            </div>
          </Row>
        )}
        {showCopySettingsButton && (
          <Row>
            <Button onClick={copySettings}>Use these settings on all rules</Button>
            {copied && <small style={{ color: '#7EB356' }}><Icon name="check" /> Copied</small>}
          </Row>
        )}
      </Form>
      {showDescription && (
        <RuleDescription $mousePos={mousePos}
                         className={showDescription ? 'show' : ''}>
          <p><b>File Name</b>: {formRule.name}</p>
          <p><b>Description</b>: {formRule.rule_description}</p>
          {!!formRule.product && (<p><b>Product</b>: {formRule.product}</p>)}
          {!!formRule.tags && (<p><b>Tags</b>: {formRule.tags.split(' ').join(', ')}</p>)}
        </RuleDescription>
      )}
    </>
  );
};

SelectedRuleForm.defaultProps = {
  expand: false,
  showCopySettingsButton: false,
};

export default SelectedRuleForm;
