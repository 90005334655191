import * as React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import type Parameter from 'views/logic/parameters/Parameter';
import ParameterInput from 'enterprise/parameters/components/ParameterInput';
import Input from 'components/bootstrap/Input';

const ParameterInputRow = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  .form-group {
    display: flex;
    align-items: baseline;
  }
`;

type Props = {
  parameters: Array<Parameter>,
  parameterSearchIds: { [key: string]: string | undefined },
};

const ReportParameters = ({ parameters, parameterSearchIds }: Props) => (!parameters?.length
  ? null
  : (
    <dl>
      <dd>
        {parameters.map((parameter) => (
          <Field key={`parameter-field-${parameter.name}`} name={`parameterValues.${parameter.name}`}>
            {({ field: { name, value, onBlur }, form: { setFieldValue }, meta: { error } }) => (
              <Input id="timezone" error={error}>
                <ParameterInputRow key={name}>
                  <ParameterInput parameter={parameter}
                                  searchId={parameterSearchIds[parameter.name]}
                                  value={value}
                                  onBlur={onBlur}
                                  onChange={(_field, newValue, triggerValidation) => setFieldValue(name, newValue, triggerValidation)} />
                </ParameterInputRow>
              </Input>
            )}
          </Field>
        ))}
      </dd>
    </dl>
  ));

ReportParameters.propTypes = {};

export default ReportParameters;
