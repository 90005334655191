import type Immutable from 'immutable';

import { DATA_WAREHOUSE_ROUTES, DATA_WAREHOUSE_ENABLED, DATAWAREHOUSE_ATTRIBUTE_NAME, DATAWAREHOUSE_ATTRIBUTES } from 'data-warehouse/Constants';
import DataWarehousesPage from 'data-warehouse/DataWarehousesPage';
import DataWarehouseStatus from 'data-warehouse/components/DataWarehouseStatus';
import DataWarehouseBackendPage from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendPage';
import { isPermitted } from 'util/PermissionsMixin';
import StreamDataWarehouse from 'data-warehouse/stream/StreamDataWarehouse';
import DataWarehouseJobs from 'data-warehouse/components/DataWarehouseJobs';
import dataWarehousecustomColumnRenderer from 'data-warehouse/DataWarehouseColumnRenderer';
import DataWarehouseConfigPage from 'data-warehouse/DataWarehouseConfigPage';
import StreamIndexSetDataWarehouseWarning from 'data-warehouse/stream/StreamIndexSetDataWarehouseWarning';
import DataWarehouseStreamDeleteWarning from 'data-warehouse/stream/DataWarehouseStreamDeleteWarning';
import { fetchStreamDataWarehouse } from 'data-warehouse/hooks/useStreamDatawarehouse';
import { fetchStreamDataWarehouseStatus } from 'data-warehouse/hooks/useStreamDataWarehouseStatus';
import StreamIlluminateProcessingSection from 'data-warehouse/stream/StreamIlluminateProcessingSection';

const getStreamDataWarehouseTableElements = (permissions: Immutable.List<string>) => {
  if (!isPermitted(permissions, 'archive:read')) {
    return null;
  }

  return {
    attributeName: DATAWAREHOUSE_ATTRIBUTE_NAME,
    attributes: DATAWAREHOUSE_ATTRIBUTES,
    columnRenderer: dataWarehousecustomColumnRenderer,
  };
};

const dataWarehouseExport = {
  routes: DATA_WAREHOUSE_ENABLED ? [
    {
      path: DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST,
      component: DataWarehousesPage,
    },
    {
      path: DATA_WAREHOUSE_ROUTES.BACKEND,
      component: DataWarehouseBackendPage,
    },
    {
      path: DATA_WAREHOUSE_ROUTES.CONFIGURATION,
      component: DataWarehouseConfigPage,
    },
  ] : [],

  dataWarehouse: DATA_WAREHOUSE_ENABLED ? [
    {
      DataWarehouseStatus,
      StreamDataWarehouse,
      DataWarehouseJobs,
      StreamIndexSetDataWarehouseWarning,
      fetchStreamDataWarehouse,
      fetchStreamDataWarehouseStatus,
      DataWarehouseStreamDeleteWarning,
      getStreamDataWarehouseTableElements,
      StreamIlluminateProcessingSection,
    },
  ] : [],
};

export default dataWarehouseExport;
