import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGenericS3App from './EmbeddedGenericS3App';

const GenericS3App: React.FC = () => (
  <>
    <PageHeader title="AWS S3 Input">
      <span>This feature retrieves log messages from an S3 Bucket.</span>
    </PageHeader>
    <EmbeddedGenericS3App />
  </>
);

export default GenericS3App;
