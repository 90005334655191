import * as React from 'react';
import styled, { css } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

import useCurrentUser from 'hooks/useCurrentUser';
import { Input, ControlLabel, Label, Button } from 'components/bootstrap';
import { Select } from 'components/common';
import {
  useUpdateInvestigations,
  useGetPriorities,
  useGetStatuses,
} from 'security-app/hooks/useInvestigationsAPI';
import type {
  InvestigationsDetailsAPIType,
  PriorityAPIType,
  StatusAPIType,
} from 'security-app/hooks/api/investigationsAPI.types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import { useUsersAndTeamsOptions } from '../hooks/useUsersAndTeamsOptions';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div<{ $gap?: string, $fullWidth?: boolean }>(({ $gap, $fullWidth }) => css`
  display: flex;
  flex-direction: row;
  gap: ${$gap || '1rem'};
  align-items: flex-end;

  & > * {
    width: ${$fullWidth ? '100%' : 'auto'};
  }
`);

const FormGroup = styled.div(({ theme }) => css`
  input[readonly] {
    background-color: ${theme.colors.global.contentBackground} !important;
    color: ${theme.colors.global.textDefault} !important;
  }

  margin-bottom: 0 !important;

  & .form-group {
    margin-bottom: 0 !important;
    margin: 0;
  }
`);

const DefaultLabel = styled(Label)`
  display: inline-flex;
  margin-left: 5px;
  vertical-align: inherit;
`;

type FormFieldType = {
  name: string,
  value: string,
  valid: boolean,
  touched: boolean,
  required: boolean,
  errorMessage?: string,
};

type FormType = {
  [key: string]: FormFieldType,
};

type OptionType = {
  value: string,
  label: string,
  default: boolean,
};

const INIT_INVESTIGATION = {
  name: '',
  assigned_to: '',
  priority: 0,
  status: '',
};

const REQURED_FIELDS = ['name'];

const initForm = (investigation: Partial<InvestigationsDetailsAPIType> = INIT_INVESTIGATION, priorityOptions = [], statusOptions = []) => (
  Object.entries(INIT_INVESTIGATION).reduce((acc: FormType, [field, defaultValue]: [string, string | number]) => {
    if (field === 'priority' && priorityOptions.length > 0) {
      const defaultPriority = priorityOptions.find((priority) => priority.default === true);
      const foundPriority = investigation[field] ? priorityOptions.find((priority) => priority.value === investigation[field].toString()) : undefined;
      let priorityValue;

      // When priority value cannot be matched we resort to default values
      if (!foundPriority) {
        priorityValue = !investigation.name && defaultPriority ? defaultPriority.value : defaultValue;
      } else {
        priorityValue = foundPriority.value;
      }

      acc[field] = {
        name: field,
        value: priorityValue,
        valid: true,
        touched: false,
        required: false,
      };
    } else if (field === 'status' && statusOptions.length > 0) {
      const defaultStatus = statusOptions.find((status) => status.default === true);
      const foundStatus = investigation[field] ? statusOptions.find((status) => status.value === investigation[field]) : undefined;
      let statusValue;

      // When status value cannot be matched we resort to default values
      if (!foundStatus) {
        statusValue = !investigation.name && defaultStatus ? defaultStatus.value : defaultValue;
      } else {
        statusValue = foundStatus.value;
      }

      acc[field] = {
        name: field,
        value: statusValue,
        valid: true,
        touched: false,
        required: false,
      };
    } else {
      acc[field] = {
        name: field,
        value: investigation[field] || defaultValue,
        valid: true,
        touched: false,
        required: false,
      };
    }

    if (REQURED_FIELDS.includes(field)) {
      acc[field].valid = !!investigation[field];
      acc[field].required = true;
    }

    return acc;
  }, {})
);

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Details({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const [form, setForm] = React.useState<FormType>(initForm(investigation));
  const sendTelemetry = useSendTelemetry();
  const canManageEvidence = React.useMemo(() => (
    !investigation.archived
    && (permissions.includes('investigations:edit') || permissions.includes('*'))
  ), [investigation.archived, permissions]);

  const { updateInvestigations, updatingInvestigations } = useUpdateInvestigations();

  const { priorities, loadingPriorities } = useGetPriorities();
  const priorityOptions = React.useMemo(() => (
    priorities.map((priority: PriorityAPIType) => ({
      value: priority.priority.toString(),
      label: priority.text,
      default: priority.default,
    }))
      .sort((a: { value: string }, b: { value: string }) => +a.value - +b.value)
  ), [priorities]);

  const { statuses, loadingStatuses } = useGetStatuses();
  const statusOptions = React.useMemo(() => (
    statuses.map((status: StatusAPIType) => ({ value: status.status, label: status.status, default: status.default }))
      .sort((a: { value: string }, b: { value: string }) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;

        return 0;
      })
  ), [statuses]);

  React.useEffect(() => {
    if (!loadingPriorities && !loadingStatuses) {
      setForm(initForm(investigation, priorityOptions, statusOptions));
    }
  }, [investigation, priorityOptions, loadingPriorities, statusOptions, loadingStatuses]);

  const assignToOptions = useUsersAndTeamsOptions();

  const isFormValid = React.useMemo(() => (
    Object.values(form).every((field: FormFieldType) => field.valid)
    && Object.values(form).some((field: FormFieldType) => field.touched)
    && Object.values(form).some((field: FormFieldType) => field.value)
  ), [form]);

  const handleSubmit = async (e: React.BaseSyntheticEvent) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_DETAILS_UPDATED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    e.preventDefault();
    if (!isFormValid) return;

    const updatedInvestigation = Object.values(form).reduce((acc: InvestigationsDetailsAPIType, field: FormFieldType) => {
      if (field.value !== null && field.value !== undefined) {
        acc[field.name] = field.value;
      }

      return acc;
    }, investigation);

    await updateInvestigations({ investigations: [updatedInvestigation] });
  };

  const validateField = (value: string) => !!value && value.replace(/\s/g, '').length > 0;

  const updateForm = (e: Partial<React.BaseSyntheticEvent>) => {
    if (e.preventDefault) e.preventDefault();
    const auxForm = cloneDeep(form);
    const { name: field, value } = e.target;

    auxForm[field].touched = true;
    auxForm[field].value = value;

    if (auxForm[field].required) auxForm[field].valid = validateField(value);

    setForm(auxForm);
  };

  const onBlur = (e: Partial<React.BaseSyntheticEvent>) => {
    const auxForm = cloneDeep(form);
    const { name: field } = e.target;

    auxForm[field].touched = true;
    if (auxForm[field].required) auxForm[field].valid = validateField(auxForm[field].value);

    setForm(auxForm);
  };

  const renderOption = (option: OptionType) => (
    <span key={option.value} title={option.value}>
      {option.label}{option.default && <DefaultLabel bsStyle="primary" bsSize="xsmall">Default</DefaultLabel>}
    </span>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input id={form.name.name}
               name={form.name.name}
               type="text"
               label="Name"
               data-testid={form.name.name}
               required={form.name.required}
               value={form.name.value}
               error={(!form.name.valid && form.name.touched) ? 'Required' : ''}
               bsStyle={(!form.name.valid && form.name.touched) ? 'error' : null}
               onChange={updateForm}
               onBlur={onBlur}
               readOnly={!canManageEvidence} />
      </FormGroup>
      <div>
        <ControlLabel>Assign To</ControlLabel>
        <Select id={form.assigned_to.name}
                inputProps={{ name: form.assigned_to.name, 'data-testid': form.assigned_to.name }}
                placeholder="Select user or team..."
                matchProp="label"
                onChange={(value: any) => updateForm({ target: { name: form.assigned_to.name, value } })}
                value={form.assigned_to.value}
                options={assignToOptions}
                onBlur={onBlur}
                disabled={!canManageEvidence} />
      </div>
      <Row $fullWidth>
        <div>
          <ControlLabel>Priority</ControlLabel>
          <Select id={form.priority.name}
                  inputProps={{ name: form.priority.name, 'data-testid': form.priority.name }}
                  placeholder="Select priority ..."
                  matchProp="label"
                  onChange={(value: number) => updateForm({ target: { name: form.priority.name, value: +value } })}
                  value={form.priority.value ? form.priority.value.toString() : null}
                  options={priorityOptions}
                  onBlur={onBlur}
                  disabled={!canManageEvidence}
                  optionRenderer={(option: OptionType) => renderOption(option)} />
        </div>
        <div>
          <ControlLabel>Status</ControlLabel>
          <Select id={form.status.name}
                  inputProps={{ name: form.status.name, 'data-testid': form.status.name }}
                  placeholder="Select status ..."
                  matchProp="value"
                  onChange={(value: string) => updateForm({ target: { name: form.status.name, value } })}
                  value={form.status.value}
                  options={statusOptions}
                  onBlur={onBlur}
                  disabled={!canManageEvidence}
                  optionRenderer={(option: OptionType) => renderOption(option)} />
        </div>
        {canManageEvidence && (
          <div style={{ width: 'auto' }}>
            <Button type="submit"
                    bsStyle="success"
                    onClick={handleSubmit}
                    disabled={!isFormValid || updatingInvestigations}>
              Save
            </Button>
          </div>
        )}
      </Row>
    </Form>
  );
}

export default Details;
