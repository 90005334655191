import * as React from 'react';

import type { Stream } from 'stores/streams/StreamsStore';

import DataWareHouseCell from './components/DataWarehouseCell';

const dataWarehousecustomColumnRenderer = {
  datawarehouse: {
    renderCell: (_datawarehouse: boolean, stream: Stream) => <DataWareHouseCell stream={stream} />,
    staticWidth: 130,
  },
};

export default dataWarehousecustomColumnRenderer;
