import type { Sort } from 'stores/PaginationTypes';

export const ENTITY_TABLE_ID = 'archives';
export const DEFAULT_LAYOUT = {
  entityTableId: 'archives',
  defaultPageSize: 20,
  defaultSort: { attributeId: 'archive_name', direction: 'desc' } as Sort,
  defaultDisplayedAttributes: ['archive_name', 'message_count', 'restored_indices', 'timestamp_from', 'timestamp_to'],
};
export const COLUMNS_ORDER = ['archive_name', 'message_count', 'restored_indices', 'timestamp_from', 'timestamp_to'];

export const ADDITIONAL_ATTRIBUTES = [
  { id: 'restored_indices', title: 'Restore Operations', hidden: false },
];
