import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import type { WidgetRef } from 'report/types';
import { toBackendWidgetRef } from 'report/report-creation/content/types';
import fetchWidgetDetails from 'report/report-creation/content/fetchWidgetDetails';

const generateKey = (widgets: Array<WidgetRef>) => widgets.map(({ widgetId }) => widgetId).sort();

const useWidgetDetails = (widgets: Array<WidgetRef>) => {
  const keys = useMemo(() => generateKey(widgets), [widgets]);
  const widgetRefs = useMemo(() => widgets.map(toBackendWidgetRef), [widgets]);
  const { data: widgetDetails, isLoading } = useQuery(keys, () => fetchWidgetDetails(widgetRefs), {});

  return { widgetDetails, isLoading };
};

export default useWidgetDetails;
