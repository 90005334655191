import React from 'react';

import { Spinner, Timestamp } from 'components/common';
import { Alert } from 'components/bootstrap';
import useAIQuotaStatus from 'security-app/components/Investigations/AIReport/hooks/useAIQuotaStatus';

const AIReportQuotaInfo = () => {
  const { data, isLoading, isError } = useAIQuotaStatus();

  if (isLoading) return <Spinner />;

  if (!data || isError) return null;

  if (!data.limit || !data.remaining || !data.used) return null;

  const quotaUsageInPercent = Math.round((data.used / data.limit) * 100);

  const alertStyle = quotaUsageInPercent >= 80 ? 'warning' : 'info';

  return (
    <Alert bsStyle={alertStyle} title={`${quotaUsageInPercent}% of AI quota used`}>
      You have used <strong>{quotaUsageInPercent}%</strong> of your quota ({data.remaining}/{data.limit} remaining).
      {data.reset_at && (
        <><br />Your quota will be reset on <strong><Timestamp dateTime={data.reset_at} format="shortReadable" /></strong>.</>
      )}
    </Alert>
  );
};

export default AIReportQuotaInfo;
