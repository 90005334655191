import * as React from 'react';

import { Row, Col, Alert } from 'components/bootstrap';
import OrientationSelect from 'report/report-creation/OrientationSelect';
import FormikSwitch from 'report/report-creation/FormikSwitch';
import Separator from 'report/report-creation/Separator';
import useReportForm from 'report/report-creation/useReportForm';

import PageSizeSelect from './PageSizeSelect';

const Style = () => {
  const { values: { layout, hideWidgetDescription } } = useReportForm();

  const format = layout?.format;
  const isPDF = !format || format === 'PDF';

  return (
    <>
      {!isPDF && <Alert bsStyle="warning">These options do not take effect because they can only be applied for &quot;PDF format&quot;.</Alert>}
      <Row>
        <Col md={6}>
          <PageSizeSelect disabled={!isPDF} />
        </Col>
        <Col md={6}>
          <OrientationSelect disabled={!isPDF} />
        </Col>
        <Col md={12}>
          <Separator />
          <FormikSwitch name="layout.printToc" label="Table of Contents" disabled={!isPDF} />
          <Separator />
          <FormikSwitch name="layout.footer" label="Footer" disabled={!isPDF} />
          <Separator />
          <FormikSwitch name="hideWidgetDescription" label="Hide widget description" disabled={!isPDF} />
          <Separator />
          <FormikSwitch name="hideWidgetQuery" label="Hide query in widget description" disabled={!isPDF || hideWidgetDescription} />
        </Col>
      </Row>
    </>
  );
};

export default Style;
