import React, { useCallback } from 'react';

const useTableElements = () => {
  const entityActions = useCallback((_archive) => (
    <div />
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
