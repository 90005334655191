import * as React from 'react';
import trim from 'lodash/trim';

const isNullOrEmpty = (s: string | undefined | null) => s === null || s === undefined || trim(s) === '';

type Props = {
  value: string,
}
const MessageCell = ({ value }: Props) => (isNullOrEmpty(value)
  ? <i>No further message.</i>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  : <>{value}</>);

export default MessageCell;
