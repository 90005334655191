import * as React from 'react';
import { useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import ErrorBoundary from 'export/ErrorBoundary';
import ReportRenderErrorPage from 'report/report-render-page/ReportRenderErrorPage';
import useQuery from 'routing/useQuery';
import type { TimeRangeQueryParameter } from 'views/logic/TimeRange';
import { timeRangeFromQueryParameter } from 'views/logic/TimeRange';
import RenderedReport from 'report/common/RenderedReport';
import useReport from 'report/report-history-page/useReport';
import useWidgetDetails from 'report/hooks/useWidgetDetails';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import type { WidgetRef } from 'report/types';

type RenderedReportProps = {
  reportId: string,
};

type OptionalNowParameter = {
  now?: string,
};

type ReportRenderPageQuery = ({} | TimeRangeQueryParameter) & OptionalNowParameter;

const extractTimeRangeOverride = (query: ReportRenderPageQuery) => ('rangetype' in query
  ? timeRangeFromQueryParameter(query)
  : undefined);

const referenceTimeFromQueryParameter = (query: OptionalNowParameter) => moment(query.now);

const extractNow = (query: ReportRenderPageQuery) => ('now' in query
  ? referenceTimeFromQueryParameter(query)
  : undefined);

const WidgetDetailsProvider = ({ children, widgets }: React.PropsWithChildren<{ widgets: Array<WidgetRef> }>) => {
  const { widgetDetails, isLoading } = useWidgetDetails(widgets);

  return isLoading ? null : (
    <WidgetDetailsContext.Provider value={widgetDetails}>
      {children}
    </WidgetDetailsContext.Provider>
  );
};

const RenderReport = ({ reportId }: RenderedReportProps) => {
  const query = useQuery();
  const timerangeOverride = useMemo(() => extractTimeRangeOverride(query), [query]);
  const now = useMemo(() => extractNow(query), [query]);
  const { data: report, isInitialLoading, error } = useReport(reportId);

  if (error) {
    return <ReportRenderErrorPage error={error as Error} />;
  }

  if (isInitialLoading) {
    return null;
  }

  return (
    <WidgetDetailsProvider widgets={report.widgets}>
      <RenderedReport report={report} now={now} timerangeOverride={timerangeOverride} />
    </WidgetDetailsProvider>
  );
};

const ReportRenderPage = () => {
  const { reportId } = useParams<{ reportId: string }>();

  return (
    <ErrorBoundary FallbackComponent={ReportRenderErrorPage}>
      <RenderReport reportId={reportId} />
    </ErrorBoundary>
  );
};

export default ReportRenderPage;
