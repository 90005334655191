import * as React from 'react';
import { useMemo, useContext } from 'react';
import styled from 'styled-components';

import ReportingWidget from 'report/common/ReportingWidget';
import { RenderCompletionObserver } from 'components/visualizations';
import type { Report, ReportingWidgetResult, WidgetRef, WidgetValueSummary, BackendReportWidget } from 'report/types';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import defaultWidgetTitle from 'views/components/defaultTitle';
import Spinner from 'components/common/Spinner';

const Visualization = styled.div`
  margin-top: 10px;
  margin-bottom: 30px
`;

type Props = {
  widgets: Array<WidgetRef>,
  positions: Report['positions'],
  orientation: Report['layout']['orientation'],
  onWidgetRenderComplete: (widgetId: string) => () => void,
  hideWidgetDescription: boolean,
  hideWidgetQuery: boolean,
  results: Array<ReportingWidgetResult>,
  width?: number,
};
const WIDGET_ASPECT_RATIO = { portrait: 9 / 23, landscape: 1 / 3 };
const WIDGET_WIDTH = { landscape: 1400, portrait: 1100 };

type WidgetProps = {
  onWidgetRenderComplete: Props['onWidgetRenderComplete'];
  height: number,
  width: number,
  widget: WidgetRef,
  value: WidgetValueSummary,
  hideDescription: boolean,
  hideQuery: boolean,
};

export const widgetTitle = (widget: WidgetRef, completeWidget: BackendReportWidget) => {
  if (widget?.title?.trim()) {
    return widget.title;
  }

  if (completeWidget) {
    return completeWidget?.description ?? defaultWidgetTitle(completeWidget);
  }

  return undefined;
};

const Widget = ({ onWidgetRenderComplete, hideDescription, hideQuery, height, width, value, widget }: WidgetProps) => {
  const { widgetId } = widget;
  const { widgets: widgetDetails } = useContext(WidgetDetailsContext);
  const completeWidget = widgetDetails[widgetId];
  const _handleRenderComplete = useMemo(() => onWidgetRenderComplete(widgetId), [onWidgetRenderComplete, widgetId]);
  const title = widgetTitle(widget, completeWidget);

  return completeWidget ? (
    <Visualization key={widget.widgetId}>
      <RenderCompletionObserver onRenderComplete={_handleRenderComplete}>
        <ReportingWidget widget={completeWidget}
                         value={value}
                         showHandle={false}
                         interactive={false}
                         height={height}
                         width={width}
                         hideDescription={hideDescription}
                         hideQuery={hideQuery}
                         title={title}
                         description={widget.description} />
      </RenderCompletionObserver>
    </Visualization>
  ) : <Spinner />;
};

const ReportWidgets = ({
  widgets,
  positions,
  onWidgetRenderComplete,
  hideWidgetDescription,
  hideWidgetQuery,
  results,
  orientation,
  width: defaultWidth,
}: Props) => {
  const _orientation = orientation ?? 'portrait';
  const width = defaultWidth ?? WIDGET_WIDTH[_orientation];
  const height = width * WIDGET_ASPECT_RATIO[_orientation];

  return (
    <>
      {widgets.sort((widgetA, widgetB) => {
        if (positions.length === 0) {
          return 0;
        }

        const positionA = positions.find((p) => p.dashboard_widget_id === widgetA.widgetId);
        const positionB = positions.find((p) => p.dashboard_widget_id === widgetB.widgetId);

        return (positionA && positionB ? positionA.row - positionB.row : 0);
      })
        .map((widget) => {
          const { widgetId } = widget;
          const value = results.find((result) => result.dashboard_id === widget.dashboardId && result.widget_id === widgetId)?.result;

          return (
            <Widget key={widget.widgetId}
                    widget={widget}
                    onWidgetRenderComplete={onWidgetRenderComplete}
                    value={value}
                    height={height}
                    width={width}
                    hideDescription={hideWidgetDescription}
                    hideQuery={hideWidgetQuery} />
          );
        })}
    </>
  );
};

ReportWidgets.defaultProps = {
  width: undefined,
};

export default ReportWidgets;
