import type { BackendReportWidget } from 'report/types';
import type { ParameterJson } from 'views/logic/parameters/Parameter';
import Parameter from 'views/logic/parameters/Parameter';
import fetch from 'logic/rest/FetchProvider';
import URLUtils from 'util/URLUtils';
import Widget, { type WidgetState } from 'views/logic/widgets/Widget';

type BackendWidgetRef = { dashboard_widget_id: string, dashboard_id: string };
const deserializeWidget = (widget: BackendReportWidget): BackendReportWidget => ({
  ...widget,
  config: Widget.fromJSON(widget as unknown as WidgetState).config,
});

type WidgetDetailsResponse = {
  widgets: Array<BackendReportWidget>,
  search_ids: { [parameterName: string]: string },
  parameters: { [widgetId: string]: Array<ParameterJson> }
}

export type WidgetDetails = {
  widgets: { [widgetId: string]: BackendReportWidget },
  searchIds: WidgetDetailsResponse['search_ids'],
  parameters: { [name: string]: Parameter },
}

const fetchWidgetDetails = (widgets: Array<BackendWidgetRef>): Promise<WidgetDetails> => fetch('POST', URLUtils.qualifyUrl('/plugins/org.graylog.plugins.report/widgets/details'), widgets)
  .then((response: WidgetDetailsResponse) => ({
    widgets: Object.fromEntries(response.widgets.map((w) => [w.dashboard_widget_id, deserializeWidget(w)])),
    searchIds: response.search_ids,
    parameters: Object.fromEntries(Object.entries(response.parameters).flatMap(([_widgetId, parametersJson]) => parametersJson.map((parameterJson) => [parameterJson.name, Parameter.fromJSON(parameterJson)]))),
  }));

export default fetchWidgetDetails;
