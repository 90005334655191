import * as React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { PluginStore } from 'graylog-web-plugin/plugin';
import { useRef, useMemo } from 'react';
import styled from 'styled-components';

import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import ColorMapper from 'views/components/visualizations/ColorMapper';
import Icon from 'components/common/Icon';
import searchTypeDefinition from 'views/logic/SearchType';
import ChartColorContext from 'views/components/visualizations/ChartColorContext';
import PrintingWidgetVisualization from 'report/common/PrintingWidgetVisualization';
import type { WidgetExport, SearchFilter, SearchTypeResult } from 'views/types';
import PluggableStoreProvider from 'components/PluggableStoreProvider';
import View from 'views/logic/views/View';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import ViewState from 'views/logic/views/ViewState';
import Query from 'views/logic/queries/Query';
import Search from 'views/logic/search/Search';
import Caption from 'common/components/widgetRenderers/Caption';
import Heading from 'common/components/widgetRenderers/Heading';
import WidgetDescription, { FallbackWidgetDescription } from 'common/components/widgetRenderers/WidgetDescription';
import ErrorBoundary from 'export/ErrorBoundary';
import type { TimeRange, QueryString } from 'views/logic/queries/Query';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';

export const Container = styled.div`
  font-size: 90%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    color: black;
  }

  @media print {
    padding: 0;
    page-break-inside: avoid;
  }
`;

export const VisualizationContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  .quickvalues-table {
    margin: 0 auto;
    text-align: left;
    width: 80%;
  }

  .dc-chart {
    float: none;
  }

  @media print {
    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-before: avoid;
    break-after: avoid;
    break-before: avoid;
    margin-bottom: 4rem;
  }
`;

const DragHandleIcon = styled(Icon)`
  font-size: 1.2em;
  position: absolute;
  left: 20px;
  top: 25px;

  @media print {
    display: none;
  }
`;

const _searchTypePlugin = (type: string) => {
  const typeDefinition = searchTypeDefinition(type);

  return typeDefinition && typeDefinition.handler
    ? searchTypeDefinition(type).handler
    : {
      convert: (result: unknown) => {
        // eslint-disable-next-line no-console
        console.error(`No search type handler for type '${type}' result:`, result);

        return result;
      },
    };
};

type Props<WT, VT> = {
  widget: WT,
  widgetId: string,
  value: VT,
  showHeading?: boolean,
  showCaption?: boolean,
  showHandle?: boolean,
  height?: number,
  width?: number,
  interactive: boolean,
  limitHeight?: boolean,
  hideDescription: boolean,
  hideQuery: boolean,
  header: string,
  description?: string | undefined,
}

const executionState = SearchExecutionState.empty();
const view = View.builder()
  .state({ query: ViewState.create() })
  .search(Search.builder().queries([Query.builder().id('query').build()]).build())
  .build();

type ValueTypeBase = {
  calculatedAt: string | undefined,
  errorMessage: string | undefined,
  hasError: boolean,
  result: any,
  types: Immutable.List<FieldTypeMapping>,
}

type WidgetTypeBase = {
  type: string,
  query?: QueryString | undefined,
  timerange?: TimeRange | undefined,
  streams?: Array<string>,
  config: any,
  filters?: Array<SearchFilter> | [],
}

const PrintWidget = <WT extends WidgetTypeBase, VT extends ValueTypeBase>({
  widget,
  value,
  showCaption,
  showHeading,
  showHandle,
  height,
  width,
  interactive,
  limitHeight,
  hideDescription,
  hideQuery,
  widgetId,
  header,
  description,
}: Props<WT, VT>) => {
  const { calculatedAt, hasError, errorMessage, types } = value;
  const heading = useRef(null);
  const caption = useRef(null);

  // eslint-disable-next-line no-nested-ternary
  const Description = !hideDescription
    ? description?.trim()
      ? <span>{description}</span>
      : (
        <ErrorBoundary FallbackComponent={FallbackWidgetDescription}>
          <WidgetDescription calculatedAt={calculatedAt}
                             widgetConfig={widget.config}
                             widgetTimerange={widget.timerange}
                             widgetQuery={widget.query}
                             widgetType={widget.type}
                             widgetStreams={widget.streams}
                             widgetFilters={widget.filters}
                             hideQuery={hideQuery} />
        </ErrorBoundary>
      )
    : null;

  const chartColors = useMemo(() => widget?.config?.formattingSettings?.chartColors ?? {}, [widget?.config?.formattingSettings?.chartColors]);
  const colorMap = useMemo(() => ColorMapper.create(Immutable.Map(chartColors)), [chartColors]);
  const chartColorContext = useMemo(() => ({ colors: colorMap, setColor: () => Promise.resolve() }), [colorMap]);
  const mappedResult = useMemo(() => {
    const mappedResults = value.result.filter((result) => !!result).map((searchType: SearchTypeResult) => _searchTypePlugin(searchType.type).convert(searchType));
    const widgetPlugin: WidgetExport = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());
    const { searchResultTransformer = (x: unknown) => x } = widgetPlugin || {};

    return searchResultTransformer(mappedResults);
  }, [value?.result, widget?.type]);

  const fieldTypes = useMemo(() => ({ all: types, queryFields: Immutable.Map({ query: types }) }), [types]);

  return (
    <PluggableStoreProvider view={view} isNew={false} executionState={executionState} initialQuery="query">
      <FieldTypesContext.Provider value={fieldTypes}>
        <AutoRefreshDisabledProvider>
          <ChartColorContext.Provider value={chartColorContext}>
            <Container>
              {showHandle && <DragHandleIcon name="sort" />}
              {showHeading && (
                <Heading title={header}
                         ref={heading}
                         widgetId={widgetId} />
              )}
              <VisualizationContainer>
                <PrintingWidgetVisualization widget={widget}
                                             widgetId={widgetId}
                                             result={mappedResult}
                                             hasError={hasError}
                                             errorMessage={errorMessage}
                                             types={types}
                                             captionHeight={caption.current?.scrollHeight ?? 0}
                                             headingHeight={heading.current?.scrollHeight ?? 0}
                                             height={height}
                                             width={width}
                                             interactive={interactive}
                                             limitHeight={limitHeight} />
              </VisualizationContainer>
              {(showCaption && !!Description) && <Caption text={Description} ref={caption} />}
            </Container>
          </ChartColorContext.Provider>
        </AutoRefreshDisabledProvider>
      </FieldTypesContext.Provider>
    </PluggableStoreProvider>
  );
};

PrintWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  showHeading: PropTypes.bool,
  showCaption: PropTypes.bool,
  showHandle: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  interactive: PropTypes.bool,
  limitHeight: PropTypes.bool,
};

PrintWidget.defaultProps = {
  showHeading: true,
  showCaption: true,
  showHandle: true,
  interactive: true,
  limitHeight: false,
  height: undefined,
  width: undefined,
  description: undefined,
};

export default PrintWidget;
