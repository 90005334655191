import * as React from 'react';

import NumberUtils from 'util/NumberUtils';
import { Spinner } from 'components/common';
import useLicenseMetrics from 'license/hooks/useLicenseMetrics';

const ActiveLicenseMetrics = () => {
  const {
    isLoading: isLoadingLicenseMetrics,
    isError: isErrorLicenseMetrics,
    data: licenseMetricsData,
  } = useLicenseMetrics();

  if (isLoadingLicenseMetrics) return (<Spinner />);

  if (isErrorLicenseMetrics || !licenseMetricsData) return null;

  return (
    <>
      {licenseMetricsData.current_day !== undefined && (
      <>
        <dt>Cluster Usage, Today:</dt>
        <dd>{NumberUtils.formatBytes(licenseMetricsData.current_day)}</dd>
      </>
      )}
      {licenseMetricsData.last_30_days !== undefined && (
      <>
        <dt>Cluster Overages, Last 30 days:</dt>
        <dd>{NumberUtils.formatBytes(licenseMetricsData.last_30_days)}</dd>
      </>
      )}
    </>
  );
};

export default ActiveLicenseMetrics;
