import * as React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Headline = styled.h3`
  margin-bottom: 15px;

  @media print {
    margin-top: 15px;

    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-inside: avoid;
    break-after: avoid;
  }
`;

export const widgetHeaderId = (widgetId: string) => (
  `widget-header-${widgetId}`
);

type Props = {
  title: string,
  widgetId: string,
}

const Heading = forwardRef<HTMLHeadingElement, Props>(({ title, widgetId }, ref) => (
  <Headline ref={ref} id={widgetHeaderId(widgetId)}>
    {title}
  </Headline>
));

Heading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Heading;
