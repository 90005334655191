import trim from 'lodash/trim';

import { toBackendWidgetRef } from 'report/report-creation/content/types';
import fetchWidgetDetails from 'report/report-creation/content/fetchWidgetDetails';
import type Parameter from 'views/logic/parameters/Parameter';
import type { ParameterValues } from 'report/types';
import debounceWithPromise from 'views/logic/debounceWithPromise';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';

const ifNotEmpty = (key: string, errors: { [key: string]: string }) => (Object.keys(errors).length > 0 ? { [key]: errors } : {});
const isEmptyString = (s: any) => !s || trim(s) === '';

const shouldNotBeEmpty = <T, >(values: T, keys: Array<keyof T>) => Object.fromEntries(keys.flatMap((key) => (isEmptyString(values?.[key]) ? [[key, 'Must be specified.']] : [])));

const validateParameters = (parameters: { [name: string]: Parameter }, values: ParameterValues) => shouldNotBeEmpty(values, Object.keys(parameters));

type ValidationErrors = {
  title?: string,
  layout?: {
    pageSize?: string,
    orientation?: string,
  },
  parameterValues?: { [name: string]: string },
}

const validateReportForm = async (values: ReportFormValues): Promise<ValidationErrors> => {
  const widgetRefs = values.widgets.map(toBackendWidgetRef);
  const widgetDetails = await fetchWidgetDetails(widgetRefs);

  const generalErrors = shouldNotBeEmpty(values, ['title']);
  const deliveryErrors = values.delivery.active ? shouldNotBeEmpty(values?.delivery, ['email_body', 'email_subject']) : {};
  const parameterErrors = validateParameters(widgetDetails.parameters, values.parameterValues);

  return {
    ...generalErrors,
    ...ifNotEmpty('delivery', deliveryErrors),
    ...ifNotEmpty('parameterValues', parameterErrors),
  };
};

const debouncedValidateReportForm = debounceWithPromise(validateReportForm, 350);

export default debouncedValidateReportForm;
