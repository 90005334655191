import * as React from 'react';
import URI from 'urijs';

import type { ReportHistory } from 'report/types';
import { Button } from 'components/bootstrap';
import { reportingUrl } from 'report/Constants';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

const downloadUrl = (reportId: string, jobId: string) => new URI(reportingUrl(`${reportId}/history/${jobId}/download`)).toString();

type Props = {
  id: string,
  reportHistory: ReportHistory,
}

const ReportHistoryActions = ({ id, reportHistory }: Props) => {
  const sendTelemetry = useSendTelemetry();

  const onDownload = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.DOWNLOAD, {
      app_pathname: 'report',
      app_section: 'report-history',
    });
  };

  return (
    <ButtonToolbar>
      {reportHistory.has_asset && (
        <a key="configuration-button" href={downloadUrl(id, reportHistory.id)} rel="noopener noreferrer">
          <Button disabled={!reportHistory.has_asset} bsSize="xsmall" onClick={onDownload}>
            Download
          </Button>
        </a>
      )}
    </ButtonToolbar>
  );
};

export default ReportHistoryActions;
