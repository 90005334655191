import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/common/Select';
import { Input } from 'components/bootstrap';

import FormAdvancedOptions from './FormAdvancedOptions';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

const StepSubscribe = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const {
    pollingInterval,
  } = formData;

  const availableLog = [
    { label: 'Admin', value: 'ADMIN_ACTIVITY_LOGS' },
    { label: 'Drive', value: 'DRIVE_ACTIVITY_LOGS' },
    { label: 'Authorization Token', value: 'AUTHORIZATION_TOKEN_LOGS' },
    { label: 'Message Tracking', value: 'MESSAGE_TRACKING_LOGS' },
    { label: 'Calendar', value: 'CALENDAR_ACTIVITY_LOGS' },
    { label: 'Meet', value: 'MEET_ACTIVITY_LOGS' },
    { label: 'Device', value: 'DEVICES_ACTIVITY_LOGS' },
    { label: 'Chat', value: 'CHAT_ACTIVITY_LOGS' },
    { label: 'Gmail', value: 'GMAIL_ACTIVITY_LOGS' },
    { label: 'User Accounts', value: 'USER_ACCOUNTS_LOGS' },
    { label: 'Login', value: 'LOGIN_ACTIVITY_LOGS' },
    { label: 'Groups', value: 'GROUPS_ACTIVITY_LOGS' },
    { label: 'Classroom', value: 'CLASSROOM_LOGS' },
    { label: 'Chrome', value: 'CHROME_LOGS' },
    { label: 'SAML', value: 'SAML_LOGS' },
    { label: 'Gplus', value: 'GPLUS_ACTIVITY_LOGS' }];

  const [logTypes, setLogTypes] = useState(INITIAL_FORMDATA.availableLogs.value);
  const [logTypeVal, setLogTypeVal] = useState(false);

  const handleSubmit = () => {
    if (logTypes.length === 0) {
      setLogTypeVal(true);

      return;
    }

    formData.contentTypes.value = logTypes;
    onSubmit();
  };

  const handlePageSizeChange = (errorMessage) => {
    if (errorMessage) {
      setFormError({
        full_message: errorMessage,
        nice_message: errorMessage,
      });
    } else {
      setFormError(null); // Reset formError if no error message
    }
  };

  const handleLagOffsetChange = (errorMessage) => {
    if (errorMessage) {
      setFormError({
        full_message: errorMessage,
        nice_message: errorMessage,
      });
    } else {
      setFormError(null); // Reset formError if no error message
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              disabled={formError}
              title=""
              error={formError}
              description="">
      <label className="control-label" htmlFor="logs">Logs Types to Collect</label>
      <Select multi
              name="contentTypes"
              id="contentTypes"
              onChange={(selected) => { setLogTypes(selected); }}
              options={availableLog}
              value={logTypes || ''}
              matchProp="label" />
      {logTypeVal && logTypes.length === 0 && (
        <label htmlFor="logTypes">Select at least one</label>
      )}
      <span className="help-block">The activity log for the above log types will be fetched.</span>

      <Input id="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue}
             min="1"
             max="60"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new data in google workspace. The smallest allowable interval is 5 minutes."
             label="Polling Interval" />

      <FormAdvancedOptions onChange={onChange}
                           handlePageSizeChange={handlePageSizeChange}
                           handleLagOffsetChange={handleLagOffsetChange} />

    </FormWrap>
  );
};

StepSubscribe.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepSubscribe;
