import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';

import { ApiRoutes } from './Routes';
import { toGenericS3InputCreateRequest } from './formDataAdapter';

import type {
  ErrorMessageType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';
import useFetch from '../common/hooks/useFetch';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.variant.darkest.default};
    margin: 25px 0;
    padding: 15px;
    border-radius: 4px;
    width: max-content;
  `,
);

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0 0 25px 10px;
    padding: 0;

    li {
      padding: 5px;

      :nth-of-type(odd) {
        background-color: ${theme.colors.table.row.backgroundStriped};
      }
    }

    strong {
      ::after {
        content: ":";
        margin-right: 5px;
      }
    }
  `,
);

type Props = {
  onSubmit: HandleSubmitType;
  externalInputSubmit: boolean;
};

const StepReview: React.FC<Props> = ({
  onSubmit,
  externalInputSubmit = false,
}) => {
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { formData } = useContext<FormDataContextType>(FormDataContext);

  const throttleEnabled = !!formData.genericS3ThrottleEnabled?.value;

  const [saveInput, setSaveInput] = useFetch(
    null,
    () => onSubmit(),
    'POST',
    toGenericS3InputCreateRequest(formData),
  );

  useEffect(() => {
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: (
          <span>
            We were unable to save your Input, please try again in a few
            moments.
          </span>
        ),
      });
    }
  }, [saveInput.error, setSaveInput]);

  const handleSubmit = () => {
    if (externalInputSubmit) {
      onSubmit(formData);

      return;
    }

    setSaveInput(ApiRoutes.INTEGRATIONS.GenericS3.SAVE_INPUT);
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Save and Start Input"
              loading={saveInput.loading}
              error={formError}
              description="Check out everything below to make sure it's correct, then click the button below to complete your S3 setup!">
      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name: </strong>
            <span>{formData.genericS3Name?.value}</span>
          </li>
          {formData.awsS3AccessKey?.value && (
          <li>
            <strong>AWS Access Key ID: </strong>
            <span>{formData.awsS3AccessKey?.value}</span>
          </li>
          )}
          <li>
            <strong>AWS SQS Queue name: </strong>
            <span>{formData.sqsQueueName?.value}</span>
          </li>
          <li>
            <strong>AWS S3 Region: </strong>
            <span>{formData.awsS3Region?.value}</span>
          </li>
          <li>
            <strong>Polling Interval: </strong>
            <span>{formData.pollingInterval?.value}</span>
          </li>
          <li>
            <strong>Content Format Type: </strong>
            <span>{formData.contentTypes?.value}</span>
          </li>
          <li>
            <strong>Compression Type: </strong>
            <span>{formData.compressionType?.value}</span>
          </li>
          <li>
            <strong>SQS Message Batch Size: </strong>
            <span>{formData.sqsMessageBatchSize?.value}</span>
          </li>
          <li>
            <strong>Enable Throttling: </strong>
            <span>
              <Icon name={throttleEnabled ? 'check_circle' : 'cancel'} />
            </span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

export default StepReview;
