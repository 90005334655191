import { Builder } from 'logic/rest/FetchProvider';
import { reportingUrl } from 'report/Constants';
import { downloadBLOB } from 'util/FileDownloadUtils';
import UserNotification from 'util/UserNotification';

const MIME_TO_EXTENSION = {
  'application/json': 'json',
  'application/pdf': 'pdf',
  'application/vnd.ms-excel': 'xlsx',
  'application/yaml': 'yaml',
  'application/zip': 'zip',
  'text/csv': 'csv',
  'text/xml': 'xml',
};

export const generateReport = (id: string) : Promise<Response> => new Builder('POST', `${reportingUrl(id)}/downloadNow`).build();

export const showDownloadDialog = async (reportId: string, response: Response) => {
  const blob = await response.blob();
  const contentType = response.headers.get('Content-Type');

  const fileExtension = MIME_TO_EXTENSION[blob.type];

  if (!fileExtension) {
    UserNotification.error(`Mime type "${blob.type}" is not supported`, 'Download failed');
  }

  return downloadBLOB(blob, { fileName: `Report-${reportId}.${fileExtension}`, contentType });
};
