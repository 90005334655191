import * as React from 'react';
import { useMemo, useRef } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import type { Moment } from 'moment';

import { DEFAULT_ORIENTATION, DEFAULT_SIZE, PAGE_PRINT_MARGIN_PX, PAGE_SIZES } from 'report/Constants';
import ColorSchemeContext from 'theme/ColorSchemeContext';
import useThemes from 'theme/hooks/useThemes';
import useDebouncedValue from 'hooks/useDebouncedValue';
import RenderedReport from 'report/common/RenderedReport';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';
import useElementDimensions from 'hooks/useElementDimensions';

export const REPORT_PREVIEW_ID = 'report-preview';

const Canvas = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: scroll
`;
const PreviewCanvas = styled(Canvas)(({ theme }) => css`
  background-color: ${theme.colors.gray[90]};
`);

const PreviewDisabledCanvas = styled(Canvas)(({ theme }) => css`
  background-color: ${theme.colors.global.background};
  align-items: center;
`);

const PageContainer = styled.div`
  width: 80%;
  margin: 40px 0;
  max-width: 800px;
  height: fit-content;
`;

const PageWrapper = styled.div<{ $width: number, $height: number }>(({ $width, $height }) => css`
  overflow: hidden;
  position: relative;
  width: ${$width}px;
  height: ${$height + PAGE_PRINT_MARGIN_PX * 2}px;
`);

const Page = styled.div<{ $pageScaleFactor: number, $width: number, $minHeight: number }>(({ $pageScaleFactor, $width, $minHeight }) => css`
  padding: ${PAGE_PRINT_MARGIN_PX}px;
  width: ${$width}px;
  transform: scale(${$pageScaleFactor});
  transform-origin: top left;
  min-height: ${$minHeight}px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
`);

type Props = {
  report: ReportFormValues,
  missingParameters: boolean,
}

type PDFPreviewProps = {
  report: ReportFormValues,
  now: Moment,
  missingParameters: boolean,
};

const usePaperDimensions = (pageSize = DEFAULT_SIZE, orientation = DEFAULT_ORIENTATION) => {
  const { portraitDimensions } = PAGE_SIZES.find(({ value }) => value === pageSize);

  if (orientation === 'landscape') {
    return ({
      width: portraitDimensions.height,
      height: portraitDimensions.width,
    });
  }

  return portraitDimensions;
};

const PDFPreview = React.memo(({ report, now, missingParameters }: PDFPreviewProps) => {
  const { scTheme, colorScheme } = useThemes(COLOR_SCHEME_LIGHT, true);
  const containerRef = useRef(null);
  const pageRef = useRef(null);
  const { width: containerWidth } = useElementDimensions(containerRef);
  const paperSize = usePaperDimensions(report.layout?.pageSize, report.layout?.orientation);
  const scaledPageDimensions = useElementDimensions(pageRef);
  const pageScaleFactor = containerWidth / paperSize.width;

  return (missingParameters
    ? (
      <PreviewDisabledCanvas>
        Preview is disabled while parameters are missing values.
      </PreviewDisabledCanvas>
    )
    : (
      <ColorSchemeContext.Provider value={colorScheme}>
        <ThemeProvider theme={scTheme}>
          <PreviewCanvas id={REPORT_PREVIEW_ID} data-testid="report-preview">
            <PageContainer ref={containerRef}>
              <PageWrapper $width={paperSize.width * pageScaleFactor} $height={scaledPageDimensions.height * pageScaleFactor}>
                <Page $pageScaleFactor={pageScaleFactor}
                      $width={paperSize.width}
                      $minHeight={paperSize.height}
                      ref={pageRef}>
                  <RenderedReport report={report}
                                  now={now}
                                  preview
                                  width={paperSize.width - PAGE_PRINT_MARGIN_PX * 2} />
                </Page>
              </PageWrapper>
            </PageContainer>
          </PreviewCanvas>
        </ThemeProvider>
      </ColorSchemeContext.Provider>
    ));
});

const ReportPreview = ({ report, missingParameters }: Props) => {
  const [debouncedReport] = useDebouncedValue(report, 200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const now = useMemo(() => moment(), []);

  const format = report.layout?.format;

  const isPDF = !format || format === 'PDF';

  return isPDF
    ? (
      <PDFPreview report={debouncedReport} now={now} missingParameters={missingParameters} />
    )
    : (
      <PreviewDisabledCanvas>
        No preview available for {format} format.
      </PreviewDisabledCanvas>
    );
};

const PROPERTIES_FOR_PREVIEW = ['title', 'subtitle', 'description', 'logo', 'hideWidgetDescription', 'hideWidgetQuery', 'timezone', 'parameterValues'] as const;

const compareProperties = <T, >(entity: T, entity2: T, keys: Readonly<Array<keyof T>>) => keys.every((key) => entity?.[key] === entity2?.[key]);
const layoutEqualForPreview = (layout1: Props['report']['layout'], layout2: Props['report']['layout']) => compareProperties(layout1, layout2, ['format', 'orientation', 'pageSize', 'printToc']);
const widgetsEqualForPreview = (widgets1: Props['report']['widgets'], widgets2: Props['report']['widgets']) => isEqual(widgets1, widgets2);
const positionsEqualForPreview = (positions1: Props['report']['positions'], positions2: Props['report']['positions']) => isEqual(positions1, positions2);

const areReportsEqualForPreview = ({ report: report1, missingParameters: missingParameters1 }: Props, { report: report2, missingParameters: missingParameters2 }: Props) => missingParameters1 === missingParameters2
  && compareProperties(report1, report2, PROPERTIES_FOR_PREVIEW)
  && layoutEqualForPreview(report1.layout, report2.layout)
  && widgetsEqualForPreview(report1.widgets, report2.widgets)
  && positionsEqualForPreview(report1.positions, report2.positions);

export default React.memo(ReportPreview, areReportsEqualForPreview);
