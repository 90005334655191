import React from 'react';

import type { DataWarehouse } from 'data-warehouse/Types';
import { ButtonToolbar, Table } from 'components/bootstrap';
import { Timestamp } from 'components/common';

import DataWarehouseDetailsPageSubactions from './DataWarehouseDetailsPageSubactions';

type Props = {
  archive: DataWarehouse,
}

const DataWarehouseSummary = ({ archive }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Message Count</th>
        <th>File Size</th>
        <th>From</th>
        <th>To</th>
        <th className="text-right">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{archive.message_count}</td>
        <td>0</td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td><Timestamp dateTime={archive.timestamp_from} /></td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td><Timestamp dateTime={archive.timestamp_to} /></td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td>
          <ButtonToolbar className="pull-right">
            <DataWarehouseDetailsPageSubactions archive={archive} />
          </ButtonToolbar>
        </td>
      </tr>
    </tbody>
  </Table>
);

export default DataWarehouseSummary;
