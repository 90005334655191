import * as React from 'react';

import type { DataWarehouse } from 'data-warehouse/Types';
import RestoredArchivesCell from 'data-warehouse/data-warehouse-overview/cells/RestoredArchivesCell';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import { Timestamp } from 'components/common';
import { Link } from 'components/common/router';

const customColumnRenderers = (): ColumnRenderers<DataWarehouse> => ({
  attributes: {
    archive_name: {
      renderCell: (archive_name: string, archive) => (
        <Link to={DATA_WAREHOUSE_ROUTES.ARCHIVE.show(archive.id)}>{archive_name}</Link>
      ),
    },
    restored_indices: {
      renderCell: (_restored_indices: string, archive) => <RestoredArchivesCell archive={archive} />,
    },
    timestamp_from: {
      renderCell: (_timestamp_from: string, archive) => archive?.timestamp_from
        && <Timestamp dateTime={archive.timestamp_from} />,
    },
    timestamp_to: {
      renderCell: (_timestamp_to: string, archive) => archive?.timestamp_to
        && <Timestamp dateTime={archive.timestamp_to} />,
    },
  },
});

export default customColumnRenderers;
