import * as React from 'react';
import styled from 'styled-components';
import type { OrderedMap } from 'immutable';
import { useState } from 'react';

import { MarkdownEditor } from 'components/common/MarkdownEditor';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';
import { ControlLabel, Button, Alert, Input } from 'components/bootstrap';
import { MultiSelect, TimeUnitInput, Icon, SearchFiltersFormControls, TimezoneSelect } from 'components/common';
import { useGetStreams, useGetNotifications } from 'security-app/hooks/useSigmaAPI';
import { SIGMA_GUARDRAILS } from 'security-app/components/SigmaRules/ImportRulesModal/SigmaModal';
import Store from 'logic/local-storage/Store';
import type { SigmaRuleZipValidationErrorAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import useUserDateTime from 'hooks/useUserDateTime';
import { describeExpression } from 'util/CronUtils';

import type { FileForm } from './fileFormLogic';

export const PLUGGABLE_CONTROLS_HIDDEN_KEY = 'pluggableSearchBarControlsAreHidden';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const Row = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`;

const FileName = styled.h4`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FieldContainer = styled.div`
  width: 100%;

  .form-group {
    margin-bottom: 2px;
  }
`;

const ErrorMessage = styled.small`
  color: ${({ theme }) => theme.colors.variant.danger};
`;

const StyledAlert = styled(Alert)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0;
`;

const CronCheckbox = styled(Input)`
  margin-top: -10px;
  margin-bottom: -10px;
`;

type Props = {
  selectedFile: FileForm | null,
  selectFile: (file: FileForm | null) => void,
  setFiles: (files: FileForm[] | ((prevState: FileForm[]) => FileForm[])) => void,
};

function RuleSettingsForm({ selectedFile, selectFile, setFiles }: Props) {
  const [copied, setCopied] = React.useState<boolean>(false);
  const [searchFiltersHidden, setSearchFiltersHidden] = React.useState(Store.get(PLUGGABLE_CONTROLS_HIDDEN_KEY));
  const [cronDescription, setCronDescription] = useState<string>(selectedFile.cron_expression.value ? describeExpression(selectedFile.cron_expression.value) : '');

  const { streams } = useGetStreams();
  const { userTimezone } = useUserDateTime();

  const streamOptions = React.useMemo(() => (
    streams
      .filter((stream: { is_editable: boolean }) => stream.is_editable)
      .map((stream: { id: string, title: string }) => ({ label: stream.title, value: stream.id }))
  ), [streams]);

  const { notifications } = useGetNotifications();
  const notificationOptions = React.useMemo(() => (
    notifications
      .map((notification: { id: string, title: string; }) => ({ label: notification.title, value: notification.id }))
  ), [notifications]);

  const updateForm = (newFileForm: FileForm) => {
    selectFile(newFileForm);

    setFiles((prevFiles: FileForm[]) => {
      const newFiles = [...prevFiles];
      const index = newFiles.findIndex((file: FileForm) => file.id === newFileForm.id);
      newFiles[index] = newFileForm;

      return newFiles;
    });
  };

  const updateStreamsNotifications = (value: string, field: string) => {
    const auxFile = { ...selectedFile };
    auxFile[field].value = value.split(',');
    auxFile[field].valid = true;
    auxFile[field].touched = true;

    updateForm(auxFile);
  };

  const validateTimes = (field: string, value: number, unit: string) => {
    if (!Number.isFinite(value) || value < 0) return [false, 'Must be a positive numeric value'];

    if (field === 'search_within') {
      let auxValue = ['HOURS', 'DAYS'].includes(unit) ? value * 60 : value;
      auxValue = unit === 'DAYS' ? auxValue * 24 : auxValue;
      const valid = auxValue <= SIGMA_GUARDRAILS.maxSearchWithin;

      if (!valid) return [false, 'Max value is 7 DAYS'];
    }

    if (field === 'execute_every') {
      const auxValue = unit === 'HOURS' ? value * 60 : value;
      const valid = auxValue >= SIGMA_GUARDRAILS.minExecuteEvery;

      if (!valid) return [false, 'Minimum value is 5 MINUTES'];
    }

    return [true, null];
  };

  const updateTimes = (field: string) => (newValue: number, unit: string) => {
    const auxFile = { ...selectedFile };
    auxFile[field].touched = true;
    auxFile[`${field}_unit`].touched = true;

    auxFile[field].value = newValue;
    auxFile[`${field}_unit`].value = unit;

    const [valid, error] = validateTimes(field, newValue, unit);
    auxFile[field].valid = valid;
    auxFile[field].error = error;

    updateForm(auxFile);
  };

  const updateRemediationSteps = (event: Partial<React.BaseSyntheticEvent>) => {
    const auxFile = { ...selectedFile };
    const { value, name: field } = event.target;

    auxFile[field].value = value;
    auxFile[field].touched = true;

    updateForm(auxFile);
  };

  const copySettings = () => {
    setFiles((prevFiles: FileForm[]) => prevFiles.map((file: FileForm) => ({
      ...file,
      streams: { ...selectedFile.streams },
      notifications: { ...selectedFile.notifications },
      search_within: { ...selectedFile.search_within },
      search_within_unit: { ...selectedFile.search_within_unit },
      execute_every: { ...selectedFile.execute_every },
      execute_every_unit: { ...selectedFile.execute_every_unit },
      use_cron_scheduling: { ...selectedFile.use_cron_scheduling },
      cron_expression: { ...selectedFile.cron_expression },
      cron_timezone: { ...selectedFile.cron_timezone },
      filters: { ...selectedFile.filters },
      remediation_steps: { ...selectedFile.remediation_steps },
    })));

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleSearchFiltersChange = (field: string) => (filters: OrderedMap<string, SearchFilter>) => {
    const auxFile = { ...selectedFile };
    auxFile[field].touched = true;
    auxFile[field].value = filters.toArray();

    updateForm(auxFile);
  };

  const handleCronFieldChanges = (field: string, value: string | boolean) => {
    const auxFile = { ...selectedFile };
    auxFile[field].touched = true;
    auxFile[field].value = value;

    if (field === 'use_cron_scheduling') {
      if (value) {
        auxFile.cron_timezone.value = userTimezone;
        auxFile.cron_expression.value = '';
      } else {
        auxFile.cron_timezone.value = null;
        auxFile.cron_expression.value = null;
      }
    }

    updateForm(auxFile);
  };

  const hideFiltersPreview = (value: boolean) => {
    Store.set(PLUGGABLE_CONTROLS_HIDDEN_KEY, value);
    setSearchFiltersHidden(value);
  };

  return (
    <Col>
      <FileName>{selectedFile.file.name}</FileName>
      <Row $fullWidth>
        <FieldContainer>
          <ControlLabel>Streams</ControlLabel>
          <MultiSelect id={`streams-${selectedFile.id}`}
                       matchProp="label"
                       aria-label="Select streams"
                       onChange={(value: string) => updateStreamsNotifications(value, 'streams')}
                       value={(selectedFile.streams.value).join(',')}
                       options={streamOptions} />
        </FieldContainer>
      </Row>
      <Row $fullWidth>
        <FieldContainer>
          <ControlLabel>Notifications</ControlLabel>
          <MultiSelect id={`notifications-${selectedFile.id}`}
                       matchProp="label"
                       aria-label="Select notifications"
                       onChange={(value: string) => updateStreamsNotifications(value, 'notifications')}
                       value={(selectedFile.notifications.value as string[]).join(',')}
                       options={notificationOptions} />
        </FieldContainer>
      </Row>
      <Row $fullWidth>
        <FieldContainer>
          <TimeUnitInput label="Search within"
                         name="search_within"
                         update={updateTimes('search_within')}
                         units={['HOURS', 'MINUTES', 'DAYS']}
                         value={selectedFile.search_within.value}
                         unit={selectedFile.search_within_unit.value}
                         clearable
                         required />
          {(!selectedFile.search_within.valid && selectedFile.search_within.touched) && <ErrorMessage>{selectedFile.search_within.error}</ErrorMessage>}
        </FieldContainer>
      </Row>
      <CronCheckbox id="is-cron-checkbox"
                    type="checkbox"
                    name="use_cron_scheduling"
                    label="Use Cron Scheduling"
                    checked={selectedFile.use_cron_scheduling.value}
                    onChange={(event: Event) => handleCronFieldChanges('use_cron_scheduling', (event.target as Input).checked)} />
      <Row $fullWidth>
        {selectedFile.use_cron_scheduling.value
          ? (
            <>
              <FieldContainer>
                <Input id="cron-expression"
                       name="cron_expression"
                       label="Cron Expression"
                       type="text"
                       help={(
                         <span>
                           {cronDescription || 'A Quartz cron expression to determine when the event should be run.'}
                         </span>
                       )}
                       value={selectedFile.cron_expression.value}
                       onBlur={() => setCronDescription(describeExpression(selectedFile.cron_expression.value))}
                       onChange={(event: Event) => handleCronFieldChanges('cron_expression', (event.target as Input).value)} />
              </FieldContainer>
              <FieldContainer>
                <ControlLabel>Cron Time Zone</ControlLabel>
                <TimezoneSelect id="cron_timezone"
                                value={selectedFile.cron_timezone.value || userTimezone}
                                name="cron_timezone"
                                clearable={false}
                                required
                                onChange={(newValue: string) => handleCronFieldChanges('cron_timezone', newValue)} />
              </FieldContainer>
            </>
          )
          : (
            <FieldContainer>
              <TimeUnitInput label="Execute every"
                             name="execute_every"
                             update={updateTimes('execute_every')}
                             units={['HOURS', 'MINUTES']}
                             value={selectedFile.execute_every.value}
                             unit={selectedFile.execute_every_unit.value}
                             clearable
                             required />
              {(!selectedFile.execute_every.valid && selectedFile.execute_every.touched) && <ErrorMessage>{selectedFile.execute_every.error}</ErrorMessage>}
            </FieldContainer>
          )}
      </Row>
      <Row $fullWidth>
        <FieldContainer>
          <ControlLabel>Remediation Steps  <small className="text-muted">(Optional)</small></ControlLabel>
          <MarkdownEditor id="remediation_steps"
                          height={150}
                          value={selectedFile.remediation_steps.value}
                          onChange={(newValue: string) => updateRemediationSteps({ target: { name: 'remediation_steps', value: newValue } })} />
        </FieldContainer>
      </Row>
      {!searchFiltersHidden && (
        <Row $fullWidth>
          <FieldContainer>
            <ControlLabel>Search Filters <small className="text-muted">(Optional)</small></ControlLabel>
            <SearchFiltersFormControls filters={selectedFile.filters.value}
                                       onChange={handleSearchFiltersChange('filters')}
                                       hideFiltersPreview={hideFiltersPreview} />
          </FieldContainer>
        </Row>
      )}
      <Row>
        <Button onClick={copySettings}>Use these settings on all rules</Button>
        {copied && <small style={{ color: '#7EB356' }}><Icon name="check" /> Copied</small>}
      </Row>
      {selectedFile.ruleErrors.length > 0 && (
        <StyledAlert bsStyle="danger" aria-label="Rule format errors">
          {selectedFile.ruleErrors.map((error: string | SigmaRuleZipValidationErrorAPIType) => {
            if (typeof error === 'string') return <p key={error}>{error}</p>;

            return (
              <div key={error.file_name}>
                <strong>{error.file_name}:</strong>
                {error.errors.map((ruleError: string) => <p key={ruleError}>{ruleError}</p>)}
              </div>
            );
          })}
        </StyledAlert>
      )}
      {(selectedFile.file.type === 'application/zip' && selectedFile.ruleErrors.length === 0) && (
        <StyledAlert bsStyle="info" aria-label="ZIP files info message">
          <p>All rules included in the ZIP file will share the same settings</p>
        </StyledAlert>
      )}
    </Col>
  );
}

export default RuleSettingsForm;
