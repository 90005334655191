import * as React from 'react';
import { useState } from 'react';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

import type { StreamDataWarehouseConfig } from 'data-warehouse/Types';
import useStreamDataWarehouseStatusMutation from 'data-warehouse/hooks/useStreamDataWarehouseStatusMutation';
import { ConfirmDialog, OverlayTrigger, Switch } from 'components/common';
import { Link } from 'components/common/router';
import Popover from 'components/common/Popover';
import {
  useDataWarehouseBackendConfig,
} from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackendConfig';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';

export const StyledSwitch = styled(Switch)`
  > label {
    margin-bottom: 0;
  }
`;

type Props = {
  streamId: string,
  config: StreamDataWarehouseConfig,
}

const StreamDataWarehouseEnableButton = ({ streamId, config }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onToggleStreamDataWarehouseConfigStatus } = useStreamDataWarehouseStatusMutation();
  const { data, isSuccess } = useDataWarehouseBackendConfig();
  const isDataWareHouseEnabled = config?.enabled || false;
  const action = isDataWareHouseEnabled ? 'disable' : 'enable';
  const title = isDataWareHouseEnabled ? 'Enabled' : 'Disabled';
  const activeBackendExists = isSuccess && data.active_backend;
  const queryClient = useQueryClient();

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);
  };

  const onConfirm = () => {
    onToggleStreamDataWarehouseConfigStatus({ streamIds: [streamId], enabled: !isDataWareHouseEnabled }).then(() => {
      setShowModal(false);
      queryClient.invalidateQueries(['data-warehouse', streamId]);
    });
  };

  const popover = (
    <Popover>
      No active Data Warehouse backend configured.
      Go to Data <Link to={DATA_WAREHOUSE_ROUTES.BACKEND}>Enterprise/Data Warehouse</Link> and set one up to use Data Warehouse.
    </Popover>
  );

  return (
    <>
      {showModal && (
        <ConfirmDialog show={showModal}
                       onConfirm={onConfirm}
                       onCancel={onModalClose}
                       title={`${capitalize(action)} Data Warehouse.`}>
          <>
            You are about to {`${action}`} <strong>Data warehouse</strong> on this stream.
            <p>Are you sure you want to continue?</p>
          </>
        </ConfirmDialog>
      )}
      {activeBackendExists
        ? (
          <StyledSwitch aria-label="Toggle datawarehouse"
                        name="toggle-datawarehouse"
                        checked={isDataWareHouseEnabled}
                        onChange={onButtonClick}
                        label={title} />
        )
        : (
          <OverlayTrigger placement="top"
                          trigger="click"
                          rootClose
                          overlay={popover}>
            <StyledSwitch aria-label="Toggle datawarehouse"
                          name="toggle-datawarehouse"
                          disabled
                          checked={false}
                          label={title} />
          </OverlayTrigger>
        )}
    </>
  );
};

export default StreamDataWarehouseEnableButton;
