import * as React from 'react';
import { useCallback, useMemo } from 'react';

import PaginatedEntityTable from 'components/common/PaginatedEntityTable';
import type { ReportHistory } from 'report/types';
import type { SearchParams, Sort } from 'stores/PaginationTypes';
import ReportQueryHelper from 'report/report-history-page/ReportHistoryQueryHelper';
import { STATUS_LABELS } from 'report/report-history-page/StatusCell';

import { fetchReportHistory } from './useReportHistory';
import ReportHistoryActions from './ReportHistoryActions';
import customColumnRenderers from './ColumnRenderers';

type Props = {
  id: string,
};

const columnsOrder = ['generated_at', 'status', 'recipients', 'has_asset', 'message'];

const tableLayout = {
  entityTableId: 'reports_history',
  defaultPageSize: 20,
  defaultDisplayedAttributes: ['generated_at', 'status', 'recipients', 'has_asset', 'message'],
  defaultSort: { attributeId: 'generated_at', direction: 'desc' } as Sort,
};

const additionalAttributes = [
  { id: 'recipients', title: 'Recipients', sortable: false },
];

const fetchOptions = { refetchInterval: 2000 };

const FilterValueRenderers = {
  status: (value: string) => STATUS_LABELS[value] ?? value,
};

const ReportHistoryList = ({ id: reportId }: Props) => {
  const keyFn = useCallback((searchParams: SearchParams) => ['reports', 'history', reportId, searchParams], [reportId]);
  const _fetchReportHistory = useCallback((searchParams: SearchParams) => fetchReportHistory(reportId, searchParams), [reportId]);

  const entityActions = useCallback((listItem: ReportHistory) => (
    <ReportHistoryActions id={reportId} reportHistory={listItem} />
  ), [reportId]);

  const _customColumnRenderers = useMemo(() => customColumnRenderers(reportId), [reportId]);

  return (
    <PaginatedEntityTable<ReportHistory> humanName="report archive"
                                         columnsOrder={columnsOrder}
                                         queryHelpComponent={<ReportQueryHelper />}
                                         entityActions={entityActions}
                                         tableLayout={tableLayout}
                                         fetchEntities={_fetchReportHistory}
                                         fetchOptions={fetchOptions}
                                         filterValueRenderers={FilterValueRenderers}
                                         entityAttributesAreCamelCase={false}
                                         keyFn={keyFn}
                                         additionalAttributes={additionalAttributes}
                                         columnRenderers={_customColumnRenderers}
                                         actionsCellWidth={80} />
  );
};

export default ReportHistoryList;
