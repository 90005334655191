import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button } from 'components/bootstrap';
import { DATA_WAREHOUSE_CONFIG_ACTION } from 'data-warehouse/Types';
import DataWarehouseBackendFormModal from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendFormModal';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';

type Props = {
  buttonType: 'button' | 'menuItem',
  backendId?: string,
}

const DataWarehouseBackendButton = ({ backendId, buttonType }: Props) => {
  const action: DATA_WAREHOUSE_CONFIG_ACTION = backendId ? DATA_WAREHOUSE_CONFIG_ACTION.Update : DATA_WAREHOUSE_CONFIG_ACTION.Create;
  const buttonText = action === DATA_WAREHOUSE_CONFIG_ACTION.Update ? 'Edit Data Warehouse Backend' : 'Create Data Warehouse Backend';
  const menuItemText = action === DATA_WAREHOUSE_CONFIG_ACTION.Update ? 'Edit' : 'Create';

  const [showModal, setShowModal] = useState<boolean>(false);

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);
  };

  const isMenuButton = buttonType === 'menuItem';

  return (
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.archive} hideChildren displayLicenseWarning={false}>
      {showModal && <DataWarehouseBackendFormModal backendId={backendId} onClose={onModalClose} />}
      <Button bsStyle={isMenuButton ? 'default' : 'success'}
              bsSize="medium"
              onClick={onButtonClick}>
        {isMenuButton ? menuItemText : buttonText}
      </Button>
    </LicenseCheck>
  );
};

DataWarehouseBackendButton.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'menuItem']),
  backendId: PropTypes.string,
};

DataWarehouseBackendButton.defaultProps = {
  buttonType: 'button',
  backendId: undefined,
};

export default DataWarehouseBackendButton;
