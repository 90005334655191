const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */
  batchSize: {
    defaultValue: '10000',
  },
  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  storeFullMessage: {
    value: false,
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },
  workspaceName: {
    value: '',
  },
  availableLogs: {
    value: 'DRIVE_ACTIVITY_LOGS,ADMIN_ACTIVITY_LOGS,AUTHORIZATION_TOKEN_LOGS,MESSAGE_TRACKING_LOGS,CALENDAR_ACTIVITY_LOGS,MEET_ACTIVITY_LOGS,DEVICES_ACTIVITY_LOGS,CHAT_ACTIVITY_LOGS,GMAIL_ACTIVITY_LOGS,USER_ACCOUNTS_LOGS,LOGIN_ACTIVITY_LOGS,GROUPS_ACTIVITY_LOGS,CLASSROOM_LOGS,CHROME_LOGS,SAML_LOGS,GPLUS_ACTIVITY_LOGS',
  },
  contentTypes: {
    value: '',
  },
  workspaceThrottleEnabled: {
    value: undefined,
  },
  workspaceStoreFullMessage: {
    value: undefined,
  },
  pollingInterval: {
    value: '5',
  },
  pageSize: {
    value: 1000,
  },
  lagOffset: {
    value: 0,
  },
};

export default DEFAULT_SETTINGS;
