import * as React from 'react';
import styled from 'styled-components';
import defaultTo from 'lodash/defaultTo';
import type { unitOfTime } from 'moment';
import moment from 'moment';
import type { OrderedMap } from 'immutable';
import { useState } from 'react';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useCurrentUser from 'hooks/useCurrentUser';
import useUserDateTime from 'hooks/useUserDateTime';
import { MarkdownEditor, MarkdownPreview } from 'components/common/MarkdownEditor';
import {
  useCreateSigmaRule,
  useValidateSigmaRule,
  useUpdateSigmaRule,
  useGetStreams,
  useGetNotifications,
} from 'security-app/hooks/useSigmaAPI';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';
import { Modal } from 'security-app/components/common';
import { Alert, ControlLabel, Button, Input } from 'components/bootstrap';
import {
  MultiSelect,
  SourceCodeEditor,
  TimeUnitInput,
  SearchFiltersFormControls,
  TimezoneSelect,
} from 'components/common';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import Store from 'logic/local-storage/Store';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';
import useScopePermissions from 'hooks/useScopePermissions';
import { describeExpression } from 'util/CronUtils';

export const PLUGGABLE_CONTROLS_HIDDEN_KEY = 'pluggableSearchBarControlsAreHidden';
export const SEARCH_WITHIN_TIME_UNITS = ['HOURS', 'MINUTES', 'DAYS'];
export const EXECUTE_EVERY_TIME_UNITS = ['HOURS', 'MINUTES'];

export const SIGMA_GUARDRAILS = {
  maxSearchWithin: 7 * 24 * 60, // 7 days in minutes
  minExecuteEvery: 5, // 5 minutes
};

const StyledAlert = styled(Alert)`
  margin-top: 15px;
`;

const StyledInput = styled.div`
  padding: 0.5rem 0;
`;

const ErrorMessage = styled.small`
  color: ${({ theme }) => theme.colors.variant.danger};
`;

const CronCheckbox = styled(Input)`
  margin-top: -10px;
  margin-bottom: -10px;
`;

const filterSelectedStreams = (selectedStreams: string[], streamOptions: { label: string, value: string }[]) => {
  const auxStreams = selectedStreams.filter((selectedStream: string) => (
    streamOptions.find((streamOption: { label: string, value: string }) => streamOption.value === selectedStream)
  ));

  return auxStreams;
};

type Props = {
  rule?: SigmaRuleListAPIType,
  onConfirm: () => void;
  onCancel: () => void;
}

const SigmaModal = ({ rule, onConfirm, onCancel }: Props) => {
  const { notifications } = useGetNotifications(true);
  const notificationOptions = React.useMemo(() => (
    notifications
      .map((notification: { id: string, title: string }) => ({ label: notification.title, value: notification.id }))
  ), [notifications]);

  const { streams } = useGetStreams(true);
  const streamOptions = React.useMemo(() => (
    streams
      .filter((stream: { is_editable: boolean }) => stream.is_editable)
      .map((stream: { id: string, title: string }) => ({ label: stream.title, value: stream.id }))
  ), [streams]);

  const { userTimezone } = useUserDateTime();
  const [currentRule, setCurrentRule] = React.useState(rule.source);
  const [selectedStreams, setSelectedStreams] = React.useState(rule.streams);
  const [selectedNotifications, setSelectedNotifications] = React.useState(rule.notifications);
  const [searchWithinDuration, setSearchWithinDuration] = React.useState(rule.search_within);
  const [searchWithinUnit, setSearchWithinUnit] = React.useState(rule.search_within_unit);
  const [executeEveryDuration, setExecuteEveryDuration] = React.useState(rule.execute_every);
  const [executeEveryUnit, setExecuteEveryUnit] = React.useState(rule.execute_every_unit);
  const [useCronScheduling, setUseCronScheduling] = React.useState(rule.use_cron_scheduling);
  const [cronExpression, setCronExpression] = React.useState(rule.cron_expression);
  const [cronDescription, setCronDescription] = useState<string>(rule.cron_expression ? describeExpression(rule.cron_expression) : '');
  const [cronTimezone, setCronTimezone] = React.useState(rule.cron_timezone || userTimezone);
  const [validationErrorsArr, setValidationErrorsArr] = React.useState<string[]>([]);
  const [searchWithinError, setSearchWithinError] = React.useState(false);
  const [executeEveryError, setExecuteEveryError] = React.useState(false);
  const [searchFilters, setSearchFilters] = React.useState<SearchFilter[]>(rule.filters || []);
  const [searchFiltersHidden, setSearchFiltersHidden] = React.useState(Store.get(PLUGGABLE_CONTROLS_HIDDEN_KEY));
  const [remediationSteps, setRemediationSteps] = React.useState(rule.remediation_steps || '');
  const [debouncedValidation, setDebouncedValidation] = React.useState(null);
  const sendTelemetry = useSendTelemetry();

  const { createSigmaRule } = useCreateSigmaRule();
  const { updateSigmaRule } = useUpdateSigmaRule();
  const { validateSigmaRule } = useValidateSigmaRule();

  const { permissions } = useCurrentUser();
  const canManageRules = React.useMemo(() => (
    permissions.includes('sigma_rules:edit')
    || permissions.includes('*')
  ), [permissions]);

  const { scopePermissions } = useScopePermissions(rule);
  const isMutable = React.useMemo(() => scopePermissions?.is_mutable, [scopePermissions]);

  const validateRule = React.useCallback(async (rawRule: string) => {
    if (rawRule && canManageRules) {
      const validationResponse = await validateSigmaRule(rawRule);
      setValidationErrorsArr(validationResponse?.errors);
    }
  }, [canManageRules, validateSigmaRule]);

  React.useLayoutEffect(() => {
    validateRule(rule?.source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = React.useCallback(async () => {
    const searchWithinMsValue = moment.duration(
      searchWithinDuration,
      searchWithinUnit as unitOfTime.DurationConstructor,
    ).asMilliseconds();

    const executeEveryMsValue = moment.duration(
      executeEveryDuration,
      executeEveryUnit as unitOfTime.DurationConstructor,
    ).asMilliseconds();

    if (rule.id) {
      await updateSigmaRule({
        ruleId: rule.id,
        ruleSource: currentRule,
        searchWithinMs: searchWithinMsValue,
        streams: selectedStreams,
        notifications: selectedNotifications,
        executeEveryMs: executeEveryMsValue,
        useCronScheduling: useCronScheduling,
        cronExpression: cronExpression,
        cronTimezone: cronTimezone,
        searchFilters,
        remediationSteps,
      });
    } else {
      await createSigmaRule({
        ruleSource: currentRule,
        searchWithinMs: searchWithinMsValue,
        streams: selectedStreams,
        notifications: selectedNotifications,
        executeEveryMs: executeEveryMsValue,
        useCronScheduling: useCronScheduling,
        cronExpression: cronExpression,
        cronTimezone: cronTimezone,
        searchFilters,
        remediationSteps,
      });
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP[`SIGMA_RULE_${rule.id ? 'UPDATED' : 'CREATED'}`], {
      app_pathname: 'security',
      app_section: 'sigma',
    });

    onConfirm();
  }, [
    updateSigmaRule,
    createSigmaRule,
    currentRule,
    searchWithinDuration,
    searchWithinUnit,
    selectedStreams,
    selectedNotifications,
    executeEveryDuration,
    executeEveryUnit,
    useCronScheduling,
    cronExpression,
    cronTimezone,
    searchFilters,
    remediationSteps,
    rule,
    onConfirm,
    sendTelemetry,
  ]);

  const handleCancel = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const debouncedRuleValidation = (rawRule: string) => {
    if (debouncedValidation) clearTimeout(debouncedValidation);

    setDebouncedValidation(setTimeout(() => {
      validateRule(rawRule);
    }, 800));
  };

  const handleRuleInput = (ruleInput: string) => {
    setCurrentRule(ruleInput);
    debouncedRuleValidation(ruleInput);
  };

  const validateSearchWithinTimeRange = (value: number, unit: string) => {
    if (value === null) return true;
    let auxValue = ['HOURS', 'DAYS'].includes(unit) ? value * 60 : value;
    auxValue = unit === 'DAYS' ? auxValue * 24 : auxValue;

    return auxValue <= SIGMA_GUARDRAILS.maxSearchWithin;
  };

  const handleSearchWithinTimeRangeChange = (nextValue: number, nextUnit: string) => {
    const validValue = validateSearchWithinTimeRange(nextValue, nextUnit);

    setSearchWithinError(!validValue);
    setSearchWithinDuration(nextValue);
    setSearchWithinUnit(nextUnit);
  };

  const validateExecuteEveryTimeRange = (value: number, unit: string) => {
    if (value === null) return true;
    const auxValue = unit === 'HOURS' ? value * 60 : value;

    return auxValue >= SIGMA_GUARDRAILS.minExecuteEvery;
  };

  const handleExecuteEveryTimeRangeChange = () => (nextValue: number, nextUnit: string) => {
    const validValue = validateExecuteEveryTimeRange(nextValue, nextUnit);

    setExecuteEveryError(!validValue);
    setExecuteEveryDuration(nextValue);
    setExecuteEveryUnit(nextUnit);
  };

  const handleSearchFiltersChange = (filters: OrderedMap<string, SearchFilter>) => {
    setSearchFilters(filters.toArray());
  };

  const hideFiltersPreview = (value: boolean) => {
    Store.set(PLUGGABLE_CONTROLS_HIDDEN_KEY, value);
    setSearchFiltersHidden(value);
  };

  const handleUseCronSchedulingChange = (value) => {
    setUseCronScheduling(value);

    if (value) {
      setCronExpression('');
      setCronTimezone(userTimezone);
    } else {
      setCronExpression(null);
      setCronTimezone(null);
    }
  };

  const Buttons = React.useMemo(() => (
    <>
      <Button bsStyle="default" onClick={handleCancel}>Cancel</Button>
      <Button bsStyle="success"
              onClick={canManageRules ? handleConfirm : handleCancel}
              disabled={validationErrorsArr.length > 0 || searchWithinError || executeEveryError}>
        {`${rule.id ? 'Save changes' : 'Add rule'}`}
      </Button>
    </>
  ), [canManageRules, handleCancel, handleConfirm, rule.id, searchWithinError, executeEveryError, validationErrorsArr]);

  return (
    <Modal show
           onClose={handleCancel}
           title={`${rule.id ? 'Edit' : 'Add'} sigma rule`}
           buttons={Buttons}>
      <div>
        {/* @ts-ignore */}
        <SourceCodeEditor id="sigma-editor"
                          mode="yaml"
                          theme="light"
                          height={400}
                          value={currentRule}
                          onChange={handleRuleInput}
                          onBlur={() => validateRule(currentRule)}
                          readOnly={!canManageRules || !isMutable} />
        <div>
          {validationErrorsArr.length > 0 && (
            <StyledAlert bsStyle="danger">
              {validationErrorsArr.map((errorStr: string) => (
                <p key={errorStr}>{errorStr}</p>
              ))}
            </StyledAlert>
          )}
        </div>
        <StyledInput>
          <ControlLabel>Streams <small className="text-muted">(Optional)</small></ControlLabel>
          <MultiSelect id="filter-streams"
                       matchProp="label"
                       onChange={(selected: string) => setSelectedStreams(selected === '' ? [] : selected.split(','))}
                       options={streamOptions}
                       value={defaultTo(filterSelectedStreams(selectedStreams, streamOptions), []).join(',')}
                       disabled={!canManageRules || !isMutable} />
        </StyledInput>
        <StyledInput>
          <ControlLabel>Notifications <small className="text-muted">(Optional)</small></ControlLabel>
          <MultiSelect id="filter-notifications"
                       matchProp="label"
                       onChange={(selected: string) => setSelectedNotifications(selected === '' ? [] : selected.split(','))}
                       options={notificationOptions}
                       value={defaultTo(selectedNotifications, []).join(',')}
                       disabled={!canManageRules} />
        </StyledInput>
        <StyledInput>
          <TimeUnitInput label="Search within the last"
                         update={(nextValue: number, nextUnit: string) => handleSearchWithinTimeRangeChange(nextValue, nextUnit)}
                         units={SEARCH_WITHIN_TIME_UNITS}
                         value={searchWithinDuration}
                         unit={searchWithinUnit}
                         clearable
                         hideCheckbox
                         required={canManageRules && isMutable}
                         enabled={canManageRules && isMutable} />
          {searchWithinError && <ErrorMessage>Max value is 7 DAYS</ErrorMessage>}
        </StyledInput>
        <CronCheckbox id="is-cron-checkbox"
                      type="checkbox"
                      name="use_cron_scheduling"
                      label="Use Cron Scheduling"
                      checked={useCronScheduling}
                      onChange={(event: Event) => handleUseCronSchedulingChange((event.target as Input).checked)} />
        {useCronScheduling
          ? (
            <StyledInput>
              <Input id="cron-expression"
                     name="cron_expression"
                     label="Cron Expression"
                     type="text"
                     help={(
                       <span>
                         {cronDescription || 'A Quartz cron expression to determine when the event should be run.'}
                       </span>
                     )}
                     onBlur={() => setCronDescription(describeExpression(cronExpression))}
                     value={cronExpression}
                     onChange={(event: Event) => setCronExpression((event.target as Input).value)} />
              <StyledInput />
              <ControlLabel>Cron Time Zone</ControlLabel>
              <TimezoneSelect id="cron_timezone"
                              value={cronTimezone}
                              name="cron_timezone"
                              clearable={false}
                              onChange={(newValue: string) => setCronTimezone(newValue)} />
            </StyledInput>
          )
          : (
            <StyledInput>
              <TimeUnitInput label="Execute every"
                             update={handleExecuteEveryTimeRangeChange()}
                             units={EXECUTE_EVERY_TIME_UNITS}
                             value={executeEveryDuration}
                             unit={executeEveryUnit}
                             clearable
                             hideCheckbox
                             required={canManageRules && isMutable}
                             enabled={canManageRules && isMutable} />
              {executeEveryError && <ErrorMessage>Minimum value is 5 MINUTES</ErrorMessage>}
            </StyledInput>
          )}
        <StyledInput>
          <div style={{ width: '100%' }}>
            <ControlLabel>Remediation Steps  <small className="text-muted">(Optional)</small></ControlLabel>
            {canManageRules && isMutable ? (
              <MarkdownEditor id="remediation_steps"
                              height={150}
                              value={remediationSteps}
                              onChange={(newValue: string) => setRemediationSteps(newValue)} />
            ) : (
              <MarkdownPreview show
                               withFullView
                               height={150}
                               value={remediationSteps || 'No remediation steps given'} />
            )}
          </div>
        </StyledInput>
        {(!searchFiltersHidden && canManageRules) ? (
          <StyledInput>
            <ControlLabel>Search Filters <small className="text-muted">(Optional)</small></ControlLabel>
            <SearchFiltersFormControls filters={searchFilters}
                                       onChange={handleSearchFiltersChange}
                                       hideFiltersPreview={hideFiltersPreview} />
          </StyledInput>
        ) : searchFilters.length > 0 && (
          <StyledInput>
            <ControlLabel>Search Filters</ControlLabel>
            {searchFilters.map((filter: SearchFilter) => (
              <div key={filter.id}>
                <p>{filter.title ? `${filter.title} -> ` : null}<code>{filter.queryString}</code></p>
              </div>
            ))}
          </StyledInput>
        )}
      </div>
    </Modal>
  );
};

SigmaModal.defaultProps = {
  rule: {
    source: '',
    streams: [],
    notifications: [],
    search_within: 5,
    search_within_unit: 'MINUTES',
    execute_every: 5,
    execute_every_unit: 'MINUTES',
    use_cron_scheduling: false,
    cron_expression: '',
  },
};

export default SigmaModal;
