import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 30%;
  min-height: 600px;
`;

const Headline = styled.h1`
  color: black !important;
  font-size: 2em;
  text-align: center;
  margin-bottom: 0.8em;
`;

const Subheadline = styled.h2`
  color: #555 !important;
  font-size: 1.6em;
  text-align: center;
`;

const Logo = styled.div`
  margin-left: auto;
  margin-top: 100px;
  padding: 10px;
  text-align: center;

  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

type Props = {
  title: string,
  subtitle: string | undefined,
  timezone: string | undefined,
  logo: string | undefined,
}

const CoverPage = ({ title, subtitle, timezone, logo }: Props) => (
  <Container>
    <Headline>{title}</Headline>
    <Subheadline className="has-bm">{subtitle}</Subheadline>
    <p className="text-center">All times are displayed in the time zone {timezone}.</p>
    {logo && <Logo><img src={logo} alt="report-logo" /></Logo>}
  </Container>
);

export default CoverPage;
