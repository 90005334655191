import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import GenericS3Routes from './Routes';

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const GenericS3InputConfiguration: React.FC<Props> = (
) => {
  const history = useHistory();

  useEffect(() => {
    const url = GenericS3Routes.INTEGRATIONS.GenericS3.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default GenericS3InputConfiguration;
