export enum DATA_WAREHOUSE_CONFIG_ACTION {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export type RestoreOperation = {
  id: string,
  restore_indices: Array<RestoreIndex>
  timestamp_from: string,
  timestamp_to: string,
  restored_at: string,
  index_failures: number,
  partially_restored: boolean,
  restored_documents: number,
};

export type RestoreIndex = {
  index_set_id: string,
  index_name: string
}

export type DataWarehouse = {
  id: string,
  archive_config_id: string,
  message_count: number,
  archive_name: string,
  timestamp_from: string,
  timestamp_to: string,
  stream_id: string,
  restore_history: Array<RestoreOperation>,
}

export type DataWarehouseConfig = {
  archive_name: string,
  stream_ids: Array<string>,
  retention_time: string,
  id: string,
  enabled: boolean
}

export enum JobStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Error = 'error',
  Paused = 'paused',
  Runnable = 'runnable',
  Running = 'running',
}

export enum JobType {
  Restore = 'restore',
  Optimize = 'optimize',
  Delete = 'delete',
}

export type DataWarehouseJob = {
  id: string,
  job_type: JobType,
  action: string,
  archive: Array<string>,
  timestamp_from: string,
  timestamp_to: string,
  is_cancelable: boolean,
  target_index_name: string,
  status: JobStatus,
  percent_done: number,
  started_at: string,
  next_time: string,
};

export type DataWarehouseActiveBackend = {
  active_backend: string,
};

export type StreamDataWarehouseConfig = {
  id: string,
  archive_name: string,
  enabled: boolean,
  stream_id: string,
  retention_time: number,
};
