import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import UserNotification from 'util/UserNotification';
import useDefaultPrompt from 'security-app/components/Investigations/AIReport/hooks/useDefaultPrompt';
import { Button, Input } from 'components/bootstrap';
import { useAiReportMutation } from 'security-app/components/Investigations/AIReport/hooks/useAIReport';

const Wrapper = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacings.sm};
`);

const InputWrapper = styled.div`
  width: 100%;
`;

const CustomPrompt = ({ investigationId }: { investigationId: string}) => {
  const mutateReport = useAiReportMutation(investigationId);
  const { data: defaultPrompt } = useDefaultPrompt();
  const [prompt, setPrompt] = useState('');
  const [showCustomPrompt, setShowCustomPrompt] = useState(false);

  const onChange = ({ target: { value } }) => {
    setPrompt(value);
  };

  useEffect(() => {
    setPrompt(defaultPrompt);
  }, [defaultPrompt]);

  const requestReport = () => {
    mutateReport({ prompt: prompt });
    UserNotification.success('New AI report requested.');
    setShowCustomPrompt(false);
  };

  return (
    <Wrapper>
      <Button bsStyle="link" bsSize="xs" onClick={() => setShowCustomPrompt((cur) => !cur)}>
        {showCustomPrompt ? 'Hide custom prompt settings' : 'Show custom prompt settings'}
      </Button>
      {showCustomPrompt && (
      <InputWrapper>
        <Input id="custom-prompt"
               name="custom-prompt"
               type="textarea"
               label="Custom prompt"
               value={prompt}
               onChange={onChange}
               rows={5} />
        <Button bsSize="xs" onClick={requestReport}>
          Request report
        </Button>
      </InputWrapper>
      )}
    </Wrapper>
  );
};

export default CustomPrompt;
