import * as React from 'react';
import styled, { css } from 'styled-components';
import { useEffect } from 'react';

import { Button, Alert, Modal, Col, Row } from 'components/bootstrap';
import { Spinner, ClipboardButton, Icon, ModalButtonToolbar } from 'components/common';
import useAIReport from 'security-app/components/Investigations/AIReport/hooks/useAIReport';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import Markdown from 'components/common/Markdown';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { downloadBLOB } from 'util/FileDownloadUtils';
import CustomPrompt from 'security-app/components/Investigations/AIReport/CustomPrompt';
import SatisfactionSurvey from 'security-app/components/Investigations/AIReport/SatisfactionSurvey';
import AIReportQuotaInfo from 'security-app/components/Investigations/AIReport/AIReportQuotaInfo';

type Props = {
  onClose: () => void,
  investigation: InvestigationAPIType,
}

const Wrapper = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.md};
  flex-direction: column;
  border: 1px solid ${theme.colors.cards.border};
  border-radius: ${theme.spacings.sm};
  padding: ${theme.spacings.md} ${theme.spacings.lg};
  margin-bottom: ${theme.spacings.md};
`);

const AIReportModalContent = ({ onClose, investigation }: Props) => {
  const { data, isFetching, isSuccess, isFetched, isError, error } = useAIReport(investigation.id);
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    if (isFetched && isSuccess) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.GET_REPORT_SUCCESS, {
        app_pathname: getPathnameWithoutId(pathname),
      });
    }

    if (isFetched && !isSuccess && isError) {
      sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.GET_REPORT_FAILED, {
        app_pathname: getPathnameWithoutId(pathname),
      });
    }
  }, [isSuccess, isFetched, isError, sendTelemetry, pathname]);

  const onDownload = () => {
    downloadBLOB(data.summary, { fileName: `AI_Report_${investigation.name}.txt`, contentType: 'text/plain' });
  };

  return (
    <>
      <Modal.Header>
        <h3>AI Report</h3>
      </Modal.Header>
      <Modal.Body>
        <Wrapper>
          <AIReportQuotaInfo />
          <CustomPrompt investigationId={investigation.id} />
          {isFetching ? <Spinner text="Generating AI Report..." /> : (
            <div>
              {data.summary && !isError && <Markdown text={data.summary} />}
              {isError && <Alert bsStyle="danger" title="">{error.message}</Alert>}
            </div>
          )}
        </Wrapper>
        <Row>
          <Col mdPush={7} md={5}>
            <SatisfactionSurvey />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtonToolbar>
          <Button onClick={onClose}>Close</Button>
          <ClipboardButton title="Copy Report"
                           bsStyle="info"
                           text={data.summary}
                           disabled={isFetching || isError}
                           buttonTitle="Copy Report" />
          <Button bsStyle="primary"
                  onClick={onDownload}
                  disabled={isFetching || isError}>
            <Icon name="download" /> Download Report
          </Button>
        </ModalButtonToolbar>
      </Modal.Footer>
    </>
  );
};

export default AIReportModalContent;
