import * as React from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import type { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { REPORT_TZ_FALLBACK, DEFAULT_ORIENTATION } from 'report/Constants';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';
import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import ReportParameters from 'report/report-render-page/ReportParameters';
import ReportWidgets from 'report/report-render-page/ReportWidgets';
import type { ParameterValues, WidgetRef } from 'report/types';
import { fetchAdhocReportValues } from 'report/report-render-page/fetchReportValues';
import type { TimeRange } from 'views/logic/queries/Query';
import ReportRenderErrorPage from 'report/report-render-page/ReportRenderErrorPage';
import type FetchError from 'logic/errors/FetchError';
import Spinner from 'components/common/Spinner';
import Description from 'report/common/Description';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import CoverPage from 'report/common/CoverPage';
import Toc from 'report/common/Toc';
import GlobalPDFStyles from 'report/common/GlobalPDFStyles';
import { REPORT_PREVIEW_ID } from 'report/report-creation/ReportPreview';

const PageBreak = styled.div`
  page-break-after: always;
`;

const InvisibleDiv = styled.div`
  display: none;
`;

const useUpdateRenderComplete = (widgets: Array<WidgetRef> = []) => {
  const [renderedWidgets, setRenderedWidgets] = useState<Array<string>>([]);

  const onWidgetRenderComplete = (widgetId: string) => () => {
    if (!renderedWidgets.includes(widgetId)) {
      setRenderedWidgets((prevWidgets) => ([...prevWidgets, widgetId]));
    }
  };

  return {
    renderComplete: widgets?.length === 0 || widgets.length === renderedWidgets.length,
    onWidgetRenderComplete,
  };
};

type Props = {
  report: ReportFormValues,
  now: Moment,
  timerangeOverride?: TimeRange,
  preview?: boolean,
  width?: number,
}
const queryKeyForFetch = (id: string, report: Props['report'], now: Props['now']) => ('id' in report
  ? ['report.values', id, report?.parameterValues, now?.toISOString()] as const
  : ['report.values.new', id, report?.parameterValues, now?.toISOString()] as const);

const fetchValues = (report: Props['report'], parameterValues: ParameterValues | undefined, timerangeOverride: Props['timerangeOverride'], now: Props['now']) => fetchAdhocReportValues(
  report.widgets.map(({ dashboardId: dashboard_id, widgetId: dashboard_widget_id }) => ({ dashboard_id, dashboard_widget_id })),
  parameterValues,
  timerangeOverride,
  now);

const useReportResults = (report: Props['report'], now: Props['now'], timerangeOverride: Props['timerangeOverride']) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportId = useMemo(() => uuidv4(), [report]);
  const queryKey = useMemo(() => queryKeyForFetch(reportId, report, now), [now, report, reportId]);

  return useQuery({
    queryKey,
    queryFn: () => fetchValues(report, report?.parameterValues, timerangeOverride, now),
    enabled: !!report,
  });
};

const RenderedReport = ({ report, timerangeOverride, now, preview, width }: Props) => {
  const reportTz = report.timezone ?? REPORT_TZ_FALLBACK;
  const { data, isLoading, isError, error: fetchError } = useReportResults(report, now, timerangeOverride);
  const { onWidgetRenderComplete, renderComplete } = useUpdateRenderComplete(report?.widgets);
  const reportLogo = report?.logo;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ReportRenderErrorPage error={fetchError as FetchError} />;
  }

  return (
    <AutoRefreshDisabledProvider>
      <UserDateTimeProvider tz={reportTz}>
        <GlobalPDFStyles $orientation={report.layout?.orientation ?? DEFAULT_ORIENTATION}
                         $rootSelector={preview ? `#${REPORT_PREVIEW_ID}` : undefined} />
        <CoverPage title={report.title}
                   subtitle={report.subtitle}
                   logo={reportLogo}
                   timezone={reportTz} />
        {report.layout?.printToc && report.widgets.length > 0 && (
          <>
            <PageBreak />
            <Toc widgets={report.widgets} />
          </>
        )}
        {(report.description || report.widgets.length > 0) && (
          <>
            <PageBreak />
            <Description>{report.description}</Description>
            {report.parameterValues && (
              <ReportParameters parameters={report.parameters}
                                parameterValues={report.parameterValues} />
            )}
            <ReportWidgets widgets={report.widgets}
                           width={width}
                           results={data}
                           positions={report.positions}
                           orientation={report.layout?.orientation ?? DEFAULT_ORIENTATION}
                           hideWidgetQuery={report.hideWidgetQuery}
                           hideWidgetDescription={report.hideWidgetDescription}
                           onWidgetRenderComplete={onWidgetRenderComplete} />
          </>
        )}
        {renderComplete && <InvisibleDiv id="render-complete" />}
      </UserDateTimeProvider>
    </AutoRefreshDisabledProvider>
  );
};

RenderedReport.defaultProps = {
  timerangeOverride: undefined,
  preview: false,
  width: undefined,
};

export default RenderedReport;
