import Reflux from 'reflux';

import UserNotification from 'util/UserNotification';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { LICENSE_SUBJECTS } from 'license/constants';

const EventsLicenseActions = Reflux.createActions({
  getLicenseStatus: { asyncResult: true },
});

const EventsLicenseStore = Reflux.createStore({
  listenables: [EventsLicenseActions],
  status: undefined,
  missing: true,
  loading: true,

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      status: this.status,
      missing: this.missing,
      loading: this.loading,
    };
  },

  propagateUpdate() {
    this.trigger(this.getState());
  },

  getLicenseStatus() {
    const promise = fetch('GET', URLUtils.qualifyUrl(EnterpriseApiRoutes.License.validityForSubject(LICENSE_SUBJECTS.events).url));
    const errorHandler = this._errorHandler('Fetching Events license failed', 'Could not retrieve Events license');

    promise.then(
      (response) => {
        this.status = response;
        this.missing = false;
        this.loading = false;
        this.propagateUpdate();
      },
      (error) => {
        try {
          // We don't want to create a user notification for missing licenses
          if (error.additional.status !== 404) {
            errorHandler(error);
          }
        } catch (e) {
          errorHandler(error);
        }

        this.status = null;
        this.missing = true;
        this.loading = false;
        this.propagateUpdate();
      },
    );

    EventsLicenseActions.getLicenseStatus.promise(promise);
  },

  _errorHandler(message, title) {
    return (error) => {
      let errorMessage;

      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }

      UserNotification.error(`${message}: ${errorMessage}`, title);
    };
  },
});

export { EventsLicenseStore, EventsLicenseActions };
