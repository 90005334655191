import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/investigations/summary';

export type AIReportPayload = {
  template?: string,
  prompt?: string,
  topP?: number,
  maxGenLength?: number,
  temperature?: number,
  model?: string,
  invalidateCachedSummary?: boolean,
}

export type AIReportPayloadJSON = {
  template?: string,
  top_p?: number,
  max_gen_length?: number,
  temperature?: number,
  model?: string,
  invalidate_cached_summary?: boolean,
}

export type AIReport = {
  summary: string,
  template: string,
  topP: number,
  maxGenLength: number,
  temperature: number,
  model: string
}

export type AIReportJSON = {
  summary: string,
  template: string,
  top_p: number,
  max_gen_length: number,
  temperature: number,
  model: string
}

const defaultState: AIReport = {
  summary: null,
  template: null,
  topP: null,
  maxGenLength: null,
  temperature: null,
  model: null,
};
const getAIReport = (investigationId: string, payload?: AIReportPayload): Promise<AIReport> => fetch<AIReportJSON>(
  'POST',
  qualifyUrl(`${urlPrefix}/${investigationId}`),
  { ...payload, invalidate_cached_summary: true },
).then((report: AIReportJSON) => ({
  summary: report.summary,
  template: report.template,
  topP: report.top_p,
  maxGenLength: report.max_gen_length,
  temperature: report.temperature,
  model: report.model,
}));

const useAiReport = (investigationId: string, payload?: AIReportPayload):{
  data: AIReport,
  isLoading: boolean,
  isFetching: boolean,
  isSuccess: boolean,
  isFetched: boolean,
  isError: boolean,
  error: Error,
} => {
  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isFetched,
    isError,
    error,
  } = useQuery<AIReport, Error>(
    ['investigation-ai-report', investigationId],
    () => getAIReport(investigationId, payload),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Fetching AI Report failed with status: ${errorThrown}.`,
          'Could not fetch AI Report');
      },
    });

  return {
    data: data ?? defaultState,
    isLoading,
    isFetching,
    isSuccess,
    isFetched,
    isError,
    error,
  };
};

export const useAiReportMutation = (investigationId: string) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (payload: AIReportPayload) => getAIReport(investigationId, payload),
    onError: (errorThrown) => {
      UserNotification.error(`Fetching AI Report failed with status: ${errorThrown}.`,
        'Could not fetch AI Report');
    },
    onSuccess: (newReport: AIReport) => {
      queryClient.setQueriesData(['investigation-ai-report', investigationId], () => newReport);
    },
  });

  return mutate;
};

export default useAiReport;
