import * as React from 'react';

import { useListGitRepos, useListRepoContent, useImportRulesFromRepo } from 'security-app/hooks/useSigmaAPI';
import { GLCheckbox, Modal } from 'security-app/components/common';
import type {
  ImportAPIResponse,
  GitRepoContent,
  GitRepoAPIModel,
  ImportGitRulePayload,
} from 'security-app/hooks/api/sigmaAPI.types';
import { Icon, Spinner } from 'components/common';
import { Button } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';

import {
  Container,
  FullSpinner,
  Window,
  WindowHeader,
  WindowFooter,
  WindowBody,
  IconButton,
} from './ImportRepoRulesModal.styles';
import PathNav from './PathNav';
import SeachRule from './SearchRule';
import RepoListItem from './RepoListItem';
import ErrorMessages from './ErrorMessages';
import SelectedRuleForm from './SelectedRuleForm/SelectedRuleForm';
import type { FormRule } from './SelectedRuleForm/SelectedRuleForm';

const initFormRule = (rule: GitRepoContent): FormRule => ({
  ...rule,
  search_within: {
    value: 5,
    touched: false,
    valid: true,
  },
  search_within_unit: {
    value: 'MINUTES',
    touched: false,
    valid: true,
  },
  execute_every: {
    value: 5,
    touched: false,
    valid: true,
  },
  execute_every_unit: {
    value: 'MINUTES',
    touched: false,
    valid: true,
  },
  use_cron_scheduling: {
    value: false,
    touched: false,
    valid: true,
  },
  cron_expression: {
    value: '',
    touched: false,
    valid: true,
  },
  cron_timezone: {
    value: null,
    touched: false,
    valid: true,
  },
  streams: {
    value: [],
    touched: false,
    valid: true,
  },
  notifications: {
    value: [],
    touched: false,
    valid: true,
  },
  filters: {
    value: [],
    touched: false,
    valid: true,
  },
  remediation_steps: {
    value: '',
    touched: false,
    valid: true,
  },
});

export type FormRuleAction =
  | { type: 'add', payload: GitRepoContent }
  | { type: 'remove', payload: GitRepoContent | FormRule }
  | { type: 'update', payload: FormRule }
  | { type: 'clear', payload: FormRule[] }
  | { type: 'copy-settings', payload: FormRule };

const formRulesReducer = (state: FormRule[], action: FormRuleAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'add':
      if (state.find((fRule: FormRule) => fRule.id === payload.id)) return state;

      return [...state, initFormRule(payload)];
    case 'remove':
      return state.filter((fRule: FormRule) => fRule.id !== payload.id);
    case 'update':
      return state.map((fRule: FormRule) => (payload.id === fRule.id ? payload : fRule));
    case 'clear':
      if (payload.length === 0) return [];

      return payload;
    case 'copy-settings':
      return state.map((formRule: FormRule) => ({
        ...formRule,
        search_within: {
          ...formRule.search_within,
          value: payload.search_within.value,
        },
        search_within_unit: {
          ...formRule.search_within_unit,
          value: payload.search_within_unit.value,
        },
        execute_every: {
          ...formRule.execute_every,
          value: payload.execute_every.value,
        },
        execute_every_unit: {
          ...formRule.execute_every_unit,
          value: payload.execute_every_unit.value,
        },
        use_cron_scheduling: {
          ...formRule.use_cron_scheduling,
          value: payload.use_cron_scheduling.value,
        },
        cron_expression: {
          ...formRule.cron_expression,
          value: payload.cron_expression.value,
        },
        cron_timezone: {
          ...formRule.cron_timezone,
          value: payload.cron_timezone.value,
        },
        streams: {
          ...formRule.streams,
          value: payload.streams.value,
        },
        notifications: {
          ...formRule.notifications,
          value: payload.notifications.value,
        },
        filters: {
          ...formRule.filters,
          value: payload.filters.value,
        },
        remediation_steps: {
          ...formRule.remediation_steps,
          value: payload.remediation_steps.value,
        },
      }));
    default:
      return state;
  }
};

const formRuleToPayload = (formRule: FormRule): ImportGitRulePayload => {
  const toMilliseconds = (time: number, unit: 'HOURS'|'MINUTES') => {
    let milliseconds = time * 1000;
    if (unit === 'MINUTES' || unit === 'HOURS') milliseconds *= 60;
    if (unit === 'HOURS') milliseconds *= 60;

    return milliseconds;
  };

  return {
    id: formRule.id,
    search_within_ms: toMilliseconds(formRule.search_within.value, formRule.search_within_unit.value),
    execute_every_ms: toMilliseconds(formRule.execute_every.value, formRule.execute_every_unit.value),
    use_cron_scheduling: formRule.use_cron_scheduling.value,
    cron_expression: formRule.cron_expression.value,
    cron_timezone: formRule.cron_timezone.value,
    streams: formRule.streams.value,
    notifications: formRule.notifications.value,
    filters: formRule.filters.value as SearchFilter[],
    remediation_steps: formRule.remediation_steps.value,
  };
};

export type ImportErrorsType = {
  ruleId: string,
  message: string,
};

type Props = {
  show: boolean,
  onClose: () => void,
};

const ImportRepoRulesModal = ({ show, onClose }: Props) => {
  const [repo, setRepo] = React.useState<GitRepoAPIModel>(null);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [formRules, formRulesDispatch] = React.useReducer(formRulesReducer, []);
  const [repoPath, setRepoPath] = React.useState<string>('');
  const [importErrors, setImportErrors] = React.useState<ImportErrorsType[]>([]);

  const { repos, loadingRepos } = useListGitRepos({ page: 1, perPage: 10000 }, show);
  const { repoRules, loadingRepoRules } = useListRepoContent(repo?.id, repoPath, searchQuery);
  const { importRulesFromRepo, importingRules } = useImportRulesFromRepo();
  const sendTelemetry = useSendTelemetry();

  const handleClose = React.useCallback(() => {
    setRepoPath('');
    setRepo(null);
    setSearchQuery('');
    formRulesDispatch({ type: 'clear', payload: [] });
    setImportErrors([]);
    onClose();
  }, [onClose]);

  const parseErrors = (errors: string[]) => (
    errors.map((err: string) => (
      err.match(/\[(?<ruleId>.+)\]: \[(?<message>.+)\]/).groups as ImportErrorsType
    ))
  );

  const handleConfirm = React.useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.SIGMA_IMPORT_RULES_IMPORTED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    const payloadRules: ImportGitRulePayload[] = formRules.map((formRule: FormRule) => formRuleToPayload(formRule));

    importRulesFromRepo({ payload: payloadRules }, {
      onSuccess: (data: ImportAPIResponse) => {
        if (data.failure_count > 0) {
          const errors = parseErrors(data.errors);

          formRulesDispatch({
            type: 'clear',
            payload: formRules.filter(
              (formRule: FormRule) => !!errors.find((err: ImportErrorsType) => err.ruleId === formRule.id),
            ),
          });

          setImportErrors(errors);
        } else {
          formRulesDispatch({ type: 'clear', payload: [] });
          setImportErrors([]);
          setRepo(null);
          onClose();
        }

        setRepoPath('');
      },
    });
  }, [formRules, importRulesFromRepo, onClose, sendTelemetry]);

  const toggleAll = (event: React.BaseSyntheticEvent) => {
    if (event.target.checked) {
      const rulesToAdd = repoRules.filter(
        (rule: GitRepoContent) => !formRules.find((formRule: FormRule) => formRule.id === rule.id)
          && rule.type === 'file',
      );
      rulesToAdd.forEach((repoRule: GitRepoContent) => formRulesDispatch({ type: 'add', payload: repoRule }));
    } else {
      formRulesDispatch({
        type: 'clear',
        payload: formRules.filter(
          (formRule: FormRule) => !repoRules.find((rule: GitRepoContent) => rule.id === formRule.id),
        ),
      });
    }
  };

  const clearSelection = () => {
    formRulesDispatch({ type: 'clear', payload: [] });
  };

  const areAllSelected = () => (
    repoRules?.filter((rule: GitRepoContent) => rule.type === 'file').every(
      (rule: GitRepoContent) => !!formRules.find((formRule: FormRule) => formRule.id === rule.id),
    )
  );

  const Buttons = React.useMemo(() => (
    <>
      <Button bsStyle="default" onClick={handleClose}>Cancel</Button>
      <Button bsStyle="success"
              onClick={handleConfirm}
              disabled={formRules.length === 0 || importErrors.length > 0 || importingRules}>
        Import rules
      </Button>
    </>
  ), [formRules, importErrors, importingRules, handleClose, handleConfirm]);

  return show && (
    <Modal show
           onClose={handleClose}
           data-telemetry-title="Viewing Repo Rules"
           maxWidth="1010px"
           title={<div>Viewing: <small>{repo?.name || ''}</small></div>}
           buttons={Buttons}>
      <div>
        <Container>
          <Window data-testid="navigator" $width={45}>
            <WindowHeader data-testid="path-title">
              {repoRules?.some((rule: any) => rule.type === 'file') && (
                <GLCheckbox checked={areAllSelected()} onChange={toggleAll} title="Select all rules" />
              )}
              <PathNav repo={repo} repoPath={repoPath} setRepoPath={setRepoPath} setRepo={setRepo} />
            </WindowHeader>
            <WindowBody>
              {(loadingRepoRules || loadingRepos) ? <Spinner /> : (repoRules || repos).map((item: GitRepoContent & GitRepoAPIModel) => (
                <RepoListItem key={`import-modal-${item.id}`}
                              item={item}
                              repoPath={repoPath}
                              formRules={formRules}
                              setRepo={setRepo}
                              setRepoPath={setRepoPath}
                              formRulesDispatch={formRulesDispatch} />
              ))}
            </WindowBody>
            {repo && (
              <WindowFooter>
                <SeachRule query={searchQuery}
                           searching={loadingRepoRules}
                           onSearch={(query: string) => setSearchQuery(query)} />
              </WindowFooter>
            )}
          </Window>
          <Window data-testid="selection" $width={65}>
            <WindowHeader style={{ justifyContent: 'space-between' }}>
              Selected Rules: {formRules.length}
              {formRules.length > 0 && (
                <IconButton data-testid="clear-selection" onClick={clearSelection}>
                  <Icon style={{ cursor: 'pointer' }}
                        name="close"
                        title="Clear selection" />
                </IconButton>
              )}
            </WindowHeader>
            <WindowBody>
              {formRules.map((formRule: FormRule, idx: number) => (
                <SelectedRuleForm key={`import-modal-selected-${formRule.id}`}
                                  expand={idx === 0}
                                  inFormRule={formRule}
                                  showCopySettingsButton={formRules.length > 1}
                                  formRulesDispatch={formRulesDispatch} />
              ))}
            </WindowBody>
          </Window>
        </Container>
        <ErrorMessages importErrors={importErrors} setImportErrors={setImportErrors} />
        {importingRules && <FullSpinner><Spinner text="Importing rules ..." /></FullSpinner>}
      </div>
    </Modal>
  );
};

export default ImportRepoRulesModal;
