import type { PluginExports } from 'graylog-web-plugin/plugin';

import filterMenuItems, { filterCloudMenuItems } from 'util/conditional/filterMenuItems';
import { IlluminateDropdownBadge, IlluminateLinkBadge } from 'common/components';
import { DATA_WAREHOUSE_ENABLED } from 'data-warehouse/Constants';
import Permissions from 'security-app/Permissions';
import useLicenseCheck from 'license/useLicenseCheck';
import Routes from 'routing/Routes';
import SecurityRoutes from 'security-app/routing/Routes';

import EnterpriseRoutes from './EnterpriseRoutes';

const DEFAULT_PERSPECTIVE_SECURITY_PAGES = [
  { path: Routes.SECURITY.ACTIVITY, description: 'Activity', permissions: Permissions.securityApp.read },
  { path: SecurityRoutes.SECURITY_EVENTS.ALERTS.LIST, description: 'Security Events', permissions: 'security_event:read' },
  { path: SecurityRoutes.INVESTIGATIONS.LIST, description: 'Investigations', permissions: 'investigations:read' },
  { path: Routes.SECURITY.ANOMALIES, description: 'Anomalies', permissions: 'anomaly_configuration:read' },
  { path: SecurityRoutes.SIGMA.LIST, description: 'Sigma Rules', permissions: 'sigma_rules:read' },
  { path: SecurityRoutes.ASSETS.LIST, description: 'Assets', permissions: 'asset:read' },
];

export const SECURITY_PAGES = [
  { path: Routes.SECURITY.ACTIVITY, description: 'Activity', permissions: Permissions.securityApp.read },
  { path: SecurityRoutes.SECURITY_EVENTS.ALERTS.LIST, description: 'Security Events', permissions: 'security_event:read' },
  { path: SecurityRoutes.INVESTIGATIONS.LIST, description: 'Investigations', permissions: 'investigations:read' },
  { path: Routes.DASHBOARDS, description: 'Dashboards' },
  { path: Routes.SECURITY.ANOMALIES, description: 'Anomalies', permissions: 'anomaly_configuration:read' },
  { path: SecurityRoutes.SIGMA.LIST, description: 'Sigma Rules', permissions: 'sigma_rules:read' },
  { path: SecurityRoutes.ASSETS.LIST, description: 'Assets', permissions: 'asset:read' },
  { path: Routes.SEARCH, description: 'Search' },
];

const bindings: PluginExports = {
  navigation: [
    {
      description: 'Enterprise',
      children: filterCloudMenuItems(
        filterMenuItems(
          [
            { path: EnterpriseRoutes.OVERVIEW, description: 'Overview', permissions: 'licenseinfos:read' },
            { path: EnterpriseRoutes.ARCHIVES, description: 'Archives', permissions: 'archive:read' },
            { path: EnterpriseRoutes.DATA_WAREHOUSE, description: 'Data Warehouse', permissions: 'archive:read' },
            { path: EnterpriseRoutes.AUDIT_LOG, description: 'Audit Log', permissions: 'auditlog_status:read' },
            { path: EnterpriseRoutes.LICENSES, description: 'Licenses', permissions: 'licenses:read' },
            { path: EnterpriseRoutes.REPORTS, description: 'Reports', permissions: 'report:update' },
            { path: EnterpriseRoutes.CUSTOMIZATION, description: 'Customization', permissions: 'clusterconfig:read' },
            { path: EnterpriseRoutes.FORWARDERS, description: 'Forwarders', permissions: 'forwarders:edit' },
            { path: EnterpriseRoutes.ILLUMINATE, description: 'Illuminate', permissions: '*', BadgeComponent: IlluminateLinkBadge },
            { path: EnterpriseRoutes.MY_SEARCH_FILTERS, description: 'My Search Filters' },
          ],
          DATA_WAREHOUSE_ENABLED ? [] : [EnterpriseRoutes.DATA_WAREHOUSE],
        ),
        [EnterpriseRoutes.LICENSES, EnterpriseRoutes.FORWARDERS],
      ),
      BadgeComponent: IlluminateDropdownBadge,
    },
    {
      description: 'Security',
      children: DEFAULT_PERSPECTIVE_SECURITY_PAGES,
      useIsValidLicense: () => {
        const { security: { isValid: isValidSecurityLicense } } = useLicenseCheck();

        return isValidSecurityLicense;
      },
    },
  ],
};

export default bindings;
